import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { UserFrameIcon } from '../../../../../../Components/icons';
import moment from 'moment';
import CountUp from 'react-countup';

const BookingTimelineCard = ({ item, handleBookingType, isSelected }) => {
  const eventHandle = () => {
    handleBookingType(item);
  };

  return (
    <Card
      style={{
        borderRadius: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 2px 0px',
        border: isSelected ? '1px solid #ABB9E8' : '1px solid transparent', // Selected border
      }}
      className="sla-card"
    >
      <CardBody style={{ padding: '0.3rem 0.7rem 1rem 1rem' }}>
        <div className="d-flex justify-content-between mt-3" onClick={eventHandle} style={{ cursor: item?.toggleAction && 'pointer' }}>
          <div>
            <UserFrameIcon />
          </div>
          <div className="text-end">
            <p className="text-black text-format-7 m-0">{item?.displayName}</p>
            <CountUp separator="" start={0} end={Number(item?.data?.totalCount)} duration={2} className="fw-bold text-format-3 m-0" />

            {item?.data?.endDate && item?.data?.startDate ? (
              <p className="mb-0 text-color-light-grey text-end">
                {moment(item?.data?.startDate).format("DD MMM'YY")} - {moment(item?.data?.endDate).format("DD MMM'YY")}
              </p>
            ) : (
              item?.data?.timelineFilter === 'upcoming' && (
                <p className="mb-0 text-color-light-grey text-end"> From {moment(item?.data?.startDate).format("DD MMM'YY")} </p>
              )
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BookingTimelineCard;
