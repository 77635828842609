import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { GET_VENDORS_LIST_DATA, GET_VENDORS_LIST_DATA_ERROR, GET_VENDORS_LIST_DATA_SUCCESS } from '../../../actions.js';

function* getVendorsList({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/vendors?booking_id=2`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_VENDORS_LIST_DATA_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VENDORS_LIST_DATA_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VENDORS_LIST_DATA_ERROR, payload: error });
  }
}

export function* watchGetVendorsList() {
  yield takeEvery(GET_VENDORS_LIST_DATA, getVendorsList);
}

function* getVendorsListSaga() {
  yield all([fork(watchGetVendorsList)]);
}

export default getVendorsListSaga;
