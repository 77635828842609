import React, { useEffect, useState } from 'react';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from './../../../pages/AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';

import { withRouter, Link, useHistory } from 'react-router-dom';

import ThriweWhite from './../../../assets/custom/thriwe-white-logo.png';

import { LOGIN_V2, LOGIN_V2_EMPTY } from '../../../store/application/actionType';
import FullLoader from '../../Common/Loader/CompleteLoader';
import { Constant } from '../../Helpers/constant';
import MicrosoftLogin from 'react-microsoft-login';

const LoginV2 = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  /* -------------------- Custom Code -------------------- */
  const { userLoginV2, userLoginV2Loading } = useSelector(state => ({
    userLoginV2: state.UserLoginV2.data,
    userLoginV2Loading: state.UserLoginV2.loading,
  }));

  console.log('userLoginV2Loading', userLoginV2Loading);

  useEffect(() => {
    if (userLoginV2Loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userLoginV2Loading]);

  useEffect(() => {
    if (![null, undefined, -1].includes(window.localStorage.getItem('authUserV2'))) {
      let redirectionRoute = `/`;
      const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
      if (userType === Constant?.userTypes?.ADMIN_USER_V2) {
        redirectionRoute = `/role-selection`;
      }
      history.push(`${redirectionRoute}`);
    }
  }, []);

  useEffect(() => {
    if (userLoginV2 !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.ADMIN_USER_V2 ?? -1));

      localStorage.setItem(
        'authUserV2',
        JSON.stringify({
          status: 'success',
          data: {
            name: `${userLoginV2?.data?.user?.firstName} ${userLoginV2?.data?.user?.lastName}`,
          },
        }),
      );

      history.push(`/role-selection`);
    }
  }, [userLoginV2]);

  const microsoftLogin = data => {
    if (!data?.account?.username?.split('@')?.includes('thriwe.com')) {
      cogoToast.error(`Login allowed for Thriwers only.`);
      return false;
    } else {
      dispatch({
        type: LOGIN_V2,
        payload: {
          data: {
            oAuthToken: data?.accessToken,
          },
        },
      });
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        {loading && <FullLoader />}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-100 text-white-50">
                  <div>
                    <Link to="/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 card-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to Thriwe.</p>
                    </div>

                    <Row className="">
                      <div className="d-flex justify-content-center">
                        <MicrosoftLogin
                          clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
                          authCallback={(err, data) => {
                            if (err) {
                              cogoToast.error('Login failed!');
                            } else {
                              microsoftLogin(data);
                            }
                          }}
                        />
                        <div></div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(LoginV2);
