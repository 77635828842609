import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { UserFrameIcon } from '../../../../../../Components/icons';
import CountUp from 'react-countup';

const BookingCard = ({ item }) => {
  return (
    <Card style={{ width: '375px', borderRadius: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 2px 0px' }} className="sla-card">
      <CardBody style={{ padding: '0.3rem 0.7rem 1rem 1rem' }}>
        <div className="d-flex justify-content-between mt-3">
          <div>
            <UserFrameIcon />
          </div>
          <div className="text-end">
            <p className="text-black text-format-7">{item?.displayName}</p>
            <CountUp separator="" start={0} end={Number(item?.data?.totalCount)} duration={2} className="text-black-1 text-format-3 m-0" />

            {/* <p className="mb-0 text-color-light-grey justify-content-end gap-2 d-flex">
          <RateValue value={item?.data?.changePercentage} fill={item?.data?.changePercentageColor ?? ''} />
          <span>Since Yesterday</span>
        </p> */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BookingCard;
