import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, CardBody, Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { GET_USER_DOWNLOADS, GET_USER_DOWNLOADS_EMPTY } from '../../../../../../store/application/actionType';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from '../../../../../Common';

const downloadTypeOptions = [
  { value: 1, label: 'User Logs' },
  { value: 2, label: 'Audit Reports' },
];

const UserDownloads = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [downloadType, setDownloadType] = useState(1);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Downloads
  const { downloads, downloadsLoading } = useSelector((state: any) => ({
    downloads: state?.GetUserDownloads?.data,
    downloadsLoading: state?.GetUserDownloads?.loading,
  }));

  console.log('downloads', downloads);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_USER_DOWNLOADS,
      payload: {
        urlParam: {
          downloadType: downloadType,
        },
      },
    });
  }, [dispatch, downloadType]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_USER_DOWNLOADS_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `DOWNLOAD`) {
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: GET_USER_DOWNLOADS,
      payload: {
        urlParam: {
          downloadType: downloadType,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (downloadsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `Downloads`) {
      // do nothing
    }
  };
  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `User Downloads`]} />
          <Card>
            <CardBody>
              <div className="live-preview">
                <div className="text-center mb-3">
                  <div className="mb-3 " style={{ background: 'rgb(243, 246, 249)', display: 'inline-block', padding: '10px', borderRadius: '12px' }}>
                    <Button color={downloadType !== 1 ? 'light' : 'primary'} className="rounded-pill" onClick={() => setDownloadType(1)}>
                      User Logs
                    </Button>
                    <Button
                      color={downloadType !== 2 ? 'light' : 'primary'}
                      className="rounded-pill"
                      onClick={() => {
                        setDownloadType(2);
                      }}
                    >
                      Audit Reports
                    </Button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Table
            data={downloads?.results}
            totalData={downloads?.total_count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default UserDownloads;
