import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import { LIST_TEMPLATE, LIST_TEMPLATE_ERROR, LIST_TEMPLATE_SUCCESS } from '../../../actionType';
import apiCall from '../../../apiCall';

function* getTemplateList({ payload }) {
  console.log('payloaddd', payload);
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/rules/workflow-templates`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: LIST_TEMPLATE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: LIST_TEMPLATE_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: LIST_TEMPLATE_ERROR, payload: error });
  }
}

export function* watchGetTemplateList() {
  yield takeEvery(LIST_TEMPLATE, getTemplateList);
}

function* getTemplateListSaga() {
  yield all([fork(watchGetTemplateList)]);
}

export default getTemplateListSaga;
