import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { GET_DASHBOARD } from '../../../../store/actions';
import { DonutChart, GeoChart, Loader } from '../../../Common';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import StatusCard from './Components/StatusCard';
import TimeSlot from './Components/TimeSlot';

const filterOptions = [
  { value: 'Date Wise', label: 'Date Wise' },
  { value: 'Global', label: 'Global' },
];

const slaData = [
  { id: 1, count: 560, status: 'No Change in Booking' },
  { id: 2, count: 450, status: 'Increased by 10' },
  { id: 3, count: 600, status: 'Decreased by 5' },
  { id: 4, count: 500, status: 'No Change in Booking' },
  { id: 5, count: 520, status: 'Increased by 3' },
];

const timeSlots = [
  { id: '12am-6am', label: '12 AM - 6 AM', startHour: 0, endHour: 6 },
  { id: '6am-12pm', label: '6 AM - 12 PM', startHour: 6, endHour: 12 },
  { id: '12pm-6pm', label: '12 PM - 6 PM', startHour: 12, endHour: 18 },
  { id: '6pm-12am', label: '6 PM - 12 AM', startHour: 18, endHour: 24 },
];

interface RootState {
  V2Dashboard?: {
    data?: any;
    loading?: boolean;
  };
}

const VendorDashboardV2: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(filterOptions[0]);
  const [activeTab, setActiveTab] = useState('today');
  const [activeTimeSlot, setActiveTimeSlot] = useState('');
  const [data, setData] = useState({ timelineWiseBooking: null, pickAndDrops: null, regionWiseChart: null });
  const [loading, setLoading] = useState(false);

  const { getDashboard, getDashboardLoading } = useSelector((state: RootState) => ({
    getDashboard: state.V2Dashboard.data,
    getDashboardLoading: state.V2Dashboard.loading,
  }));

  useEffect(() => {
    if (getDashboard !== null) {
      const isTimeLineDisplay = getDashboard?.sections?.find(data => data?.type === 'timeline_wise_bookings');
      const isChartDisplay = getDashboard?.sections?.find(data => data?.type === 'data_charts');
      const isRegionWiseChartDisplay = isChartDisplay?.cards?.find(chart => chart?.displayName === 'Region Wise Bookings');
      const isPickAndDropsDisplay = isChartDisplay?.cards?.find(chart => chart?.displayName === 'Pickup Vs Drops');
      setData({
        timelineWiseBooking: isTimeLineDisplay,
        pickAndDrops: isPickAndDropsDisplay,
        regionWiseChart: isRegionWiseChartDisplay,
      });
    }
  }, [getDashboard]);

  useEffect(() => {
    const timeSlotData = getCurrentTimeSlot();
    if (timeSlotData) {
      setActiveTimeSlot(timeSlotData.id);

      dispatch({
        type: GET_DASHBOARD,
        payload: {
          urlParams: {
            startDate: timeSlotData.startDate,
            endDate: timeSlotData.endDate,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (getDashboardLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getDashboardLoading]);

  const tabs = [
    { id: 'past', label: 'Past' },
    { id: 'yesterday', label: 'Yesterday' },
    { id: 'today', label: 'Today' },
    { id: 'tomorrow', label: 'Tomorrow' },
    ...Array.from({ length: 5 }, (_, i) => {
      const date = moment().add(i + 2, 'days');
      return { id: date.format('DMM'), label: date.format('DD MMM') };
    }),
  ];

  // Function to determine the current time slot
  const getCurrentTimeSlot = () => {
    const currentHour = moment().hour();
    const currentDate = moment().format('YYYY-MM-DD');

    for (const slot of timeSlots) {
      if (currentHour >= slot.startHour && currentHour < slot.endHour) {
        const startDate = moment(`${currentDate} ${slot.startHour}:00`).toISOString();
        const endDate = moment(`${currentDate} ${slot.endHour}:00`).toISOString();
        return { id: slot.id, startDate, endDate };
      }
    }

    return null;
  };

  const getSelectedDate = (activeTab: string) => {
    if (activeTab === 'past') {
      return moment().subtract(2, 'days');
    }
    if (activeTab === 'yesterday') {
      return moment().subtract(1, 'days');
    }
    if (activeTab === 'today') {
      return moment();
    }
    if (activeTab === 'tomorrow') {
      return moment().add(1, 'days');
    }

    // Extract date from ID like "1302" -> "13 Feb"
    if (/^\d{4}$/.test(activeTab)) {
      const day = activeTab.substring(0, 2);
      const month = activeTab.substring(2, 4);
      const formattedDate = moment(`${day}-${month}-${moment().year()}`, 'DD-MM-YYYY');
      return formattedDate;
    }

    return moment(); // Default to today if no match
  };

  const daySelectHandler = (id: string) => {
    setActiveTab(id);
    const selectedSlot = timeSlots.find(slot => slot.id === activeTimeSlot);
    const selectedDate = getSelectedDate(id);
    if (selectedSlot && selectedDate) {
      const startDate = selectedDate.set('hour', selectedSlot.startHour).set('minute', 0).set('second', 0).toISOString();
      const endDate = selectedDate.set('hour', selectedSlot.endHour).set('minute', 0).set('second', 0).toISOString();
      dispatch({
        type: GET_DASHBOARD,
        payload: {
          urlParams: { startDate, endDate },
        },
      });
    }
  };

  const timeSelectHandler = (id: string) => {
    setActiveTimeSlot(id);
    const selectedSlot = timeSlots.find(slot => slot.id === id);
    const selectedDate = getSelectedDate(activeTab);
    if (selectedSlot && selectedDate) {
      const startDate = selectedDate.set('hour', selectedSlot.startHour).set('minute', 0).set('second', 0).toISOString();
      const endDate = selectedDate.set('hour', selectedSlot.endHour).set('minute', 0).set('second', 0).toISOString();
      dispatch({
        type: GET_DASHBOARD,
        payload: {
          urlParams: { startDate, endDate },
        },
      });
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {loading && <Loader />}

        <div className="d-flex align-items-center justify-content-between">
          <BreadcrumbTrail
            title="Dashboard"
            navigationby={title => title === 'Dashboard' && history.push('/v2/vendor-dashboard')}
            navigation={['Dashboard']}
          />
          <Select
            options={filterOptions}
            value={filter}
            styles={{ control: provided => ({ ...provided, width: '200px' }) }}
            onChange={data => {
              setFilter(data);
              dispatch({
                type: GET_DASHBOARD,
              });
            }}
          />
        </div>
      </Container>

      <Card>
        <CardBody>
          {filter.value === 'Date Wise' ? (
            <>
              <Nav tabs className="nav-tabs-custom nav-success nav-justified mb-3">
                {tabs.map(tab => (
                  <NavItem key={tab.id}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${classNames({ active: activeTab === tab.id })} fs-14`}
                      onClick={() => daySelectHandler(tab.id)}
                    >
                      {tab.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={activeTab} className="text-muted">
                {tabs.map(tab => (
                  <TabPane key={tab.id} tabId={tab.id}>
                    <TimeSlot timeSlots={timeSlots} activeTimeSlot={activeTimeSlot} onTimeSlotSelect={timeSelectHandler} />
                    <div className="sla-container mt-4 p-1">
                      <div className="status-vendor-scroll">
                        {data?.timelineWiseBooking &&
                          data?.timelineWiseBooking?.cards?.map((timelineBooking, index) => (
                            <StatusCard key={index} timelineBooking={timelineBooking} />
                          ))}
                      </div>
                    </div>
                  </TabPane>
                ))}
              </TabContent>
            </>
          ) : (
            <div className="sla-container mt-4 p-1">
              <div className="status-vendor-scroll">
                {data?.timelineWiseBooking &&
                  data?.timelineWiseBooking?.cards?.map((timelineBooking, index) => (
                    <StatusCard key={index} timelineBooking={timelineBooking} />
                  ))}{' '}
              </div>
            </div>
          )}
        </CardBody>
      </Card>

      <Row>
        {data?.pickAndDrops && (
          <DonutChart data={data?.pickAndDrops?.data} lastUpdatedAt={data?.pickAndDrops?.lastUpdatedAt} title={'Pickups vs Drops'} />
        )}
        {data?.regionWiseChart && <GeoChart data={data?.regionWiseChart?.data} title={'Region Wise Bookings'} />}
      </Row>
    </div>
  );
};

export default VendorDashboardV2;
