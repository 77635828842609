
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from '@azure/msal-react';
import { msalInstanceByAdib } from './authConfigAdib';
import { msalInstanceByThriwe } from './authConfigThriwe';

const environment = process.env.REACT_APP_NODE_ENV;
const clientDSN = process.env.REACT_APP_SENTRY_DSN;

const Main = () => {
  const [msalInstance, setMsalInstance] = useState(msalInstanceByAdib);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get('auth-source');
    if (value === 'thriwe') {
      setMsalInstance(msalInstanceByThriwe);
    } else {
      setMsalInstance(msalInstanceByAdib);
    }
  }, []);

  return (
    <Provider store={configureStore({})}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ToastContainer />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<Main />);

reportWebVitals();
