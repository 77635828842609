import CountUp from 'react-countup';
import { Card, CardBody, Col, Row } from 'reactstrap';

type SurgeDataProps = {
  totalRegistrations?: number;
  totalPosts?: number;
  totalOffers?: number;
  totalConnections?: number;
  pendingApprovalRegistraions?: number;
  pendingApprovalPosts?: number;
  pendingApprovalOffers?: number;
};

const SurgeData: React.FC<SurgeDataProps> = ({
  totalRegistrations,
  totalPosts,
  totalOffers,
  totalConnections,
  pendingApprovalRegistraions,
  pendingApprovalPosts,
  pendingApprovalOffers,
}) => {
  const items = [
    {
      id: 2,
      label: 'Total Registrations',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${totalRegistrations ?? 0}`,
      icon: 'bx bx-user',
      iconClass: 'success',
    },
    {
      id: 3,
      label: 'Total Posts',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${totalPosts ?? 0}`,
      icon: 'bx bx-basket',
      iconClass: 'info',
    },
    {
      id: 4,
      label: 'Total Offers Shared by SME',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${totalOffers ?? 0}`,
      icon: 'bx bx-category',
      iconClass: 'danger',
    },
    {
      id: 5,
      label: 'Total Conncetion Request',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${totalConnections ?? 0}`,
      icon: 'bx bx-spreadsheet',
      iconClass: 'secondary',
    },
    {
      id: 6,
      label: 'Pending Approval Registrations',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${pendingApprovalRegistraions ?? 0}`,
      icon: 'bx bx-detail',
      iconClass: 'primary',
    },
    {
      id: 7,
      label: 'Pending Approval Posts',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${pendingApprovalPosts ?? 0}`,
      icon: 'bx bx-error-circle',
      iconClass: 'warning',
    },
    {
      id: 7,
      label: 'Pending Approval Offers',
      percentageIcon: 'ri-arrow-right-down-line',
      counter: `${pendingApprovalOffers ?? 0}`,
      icon: 'bx bx-error-circle',
      iconClass: 'warning',
    },
  ];

  return (
    <Row>
      {items.map(item => (
        <Col xl={3} md={6} key={item.id}>
          <Card className={'card-animate'}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className={'text-uppercase fw-medium mb-0 text-muted'}>{item.label}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className={'fs-22 fw-semibold ff-secondary'}>
                    <span className="counter-value" data-target="559.25">
                      <CountUp separator="" start={0} end={Number(item.counter)} duration={4} />
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={'avatar-title rounded fs-3 bg-soft-' + item.iconClass}>
                    <i className={item.icon + ' text-' + item.iconClass}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SurgeData;
