import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { STATUS_FILTERS_LIST, STATUS_FILTERS_LIST_ERROR, STATUS_FILTERS_LIST_SUCCESS } from '../../../actions.js';

function* statusFiltersList({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/get-booking-status-filters`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
      params: {
        ...payload?.params,
      },
    });

    if (response.status === 200) {
      yield put({
        type: STATUS_FILTERS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: STATUS_FILTERS_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: STATUS_FILTERS_LIST_ERROR, payload: error });
  }
}

export function* watchStatusFiltersList() {
  yield takeEvery(STATUS_FILTERS_LIST, statusFiltersList);
}

function* statusFiltersListSaga() {
  yield all([fork(watchStatusFiltersList)]);
}

export default statusFiltersListSaga;
