import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';
import { Card, CardBody, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { GET_BOOKING_DETAILS, GET_BOOKINGS_LIST, GET_CARD_DETAILS, GET_FILTERS_LIST, STATUS_FILTERS_LIST } from '../../../../../store/actions';
import { Loader } from '../../../../Common';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../Helpers/constant';
import Filter from './Components/Filter/Filter';
import StatusFilter from './Components/Filter/StatusFilter';
import BookingModals from './Components/Modals';
import Summary from './Components/Summary';
import DataViews from './Components/Views';

const AirportTransferBookingV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { cardId = '', statusFilter = '', timelineFilter = '', skip = 1, limit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentPageNumber, setCurrentPageNumber] = useState(Number(skip) - 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(limit));

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [loading, setLoading] = useState(false);
  const [currentActiveBookingAction, setCurrentActiveBookingAction] = useState(null);

  const [currentSelectedBooking, setCurrentSelectedBooking] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { bookingsList, bookingsListLoading, bookingDetails, bookingDetailsLoading } = useSelector((state: any) => ({
    bookingsList: state?.GetBookingsList?.data,
    bookingsListLoading: state?.GetBookingsList?.loading,

    bookingDetails: state?.GetBookingDetails?.data,
    bookingDetailsLoading: state?.GetBookingDetails?.loading,
  }));

  const { filtersList, filtersListLoading, filterValues, filterValuesLoading, statusFilters, statusFiltersLoading } = useSelector((state: any) => ({
    filtersList: state?.GetFiltersList?.data,
    filtersListLoading: state?.GetFiltersList?.loading,

    filterValues: state?.GetFilterValues?.data,
    filterValuesLoading: state?.GetFilterValues?.loading,

    statusFilters: state?.StatusFiltersList?.data,
    statusFiltersLoading: state?.StatusFiltersList?.loading,
  }));

  const { getCardDetails, getCardDetailsLoading } = useSelector((state: any) => ({
    getCardDetails: state?.GetCardDetails?.data,
    getCardDetailsLoading: state?.GetCardDetails?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_BOOKINGS_LIST,
      payload: {
        params: {
          card_id: cardId,
          timelineFilter: timelineFilter,
          booking_filter_id: statusFilter,
          pageSize: limit,
          pageIndex: (Number(skip) - 1) * Number(limit),
          ...currentlyAppliedFilters,
        },
      },
    });
  }, [statusFilter, skip, limit, currentlyAppliedFilters, cardId]);

  useEffect(() => {
    if (currentSelectedBooking) {
      dispatch({
        type: GET_BOOKING_DETAILS,
        payload: {
          params: {
            booking_id: currentSelectedBooking?.bookingId,
          },
        },
      });
    }
  }, [currentSelectedBooking]);

  useEffect(() => {
    dispatch({
      type: GET_CARD_DETAILS,
      payload: {
        params: {
          card_Id: cardId,
        },
      },
    });

    if (cardId) {
      dispatch({
        type: GET_FILTERS_LIST,
        payload: {
          urlParams: {
            card_id: cardId,
          },
        },
      });
    }

    dispatch({
      type: STATUS_FILTERS_LIST,
      payload: {
        params: {
          card_id: cardId,
        },
      },
    });

    // dispatch({
    //   type: GET_FILTER_VALUES,
    // });

    // dispatch({
    //   type: STATUS_FILTERS_LIST,
    // });
  }, [location?.search]);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      ...newParams,
    };

    const newUrl = queryString.stringify(updatedParams);
    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
    updateURLParams({ page: 1 });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    }
  };

  useEffect(() => {
    if (bookingsListLoading || bookingDetailsLoading || filtersListLoading || filterValuesLoading || statusFiltersLoading || getCardDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsListLoading, bookingDetailsLoading, filtersListLoading, filterValuesLoading, statusFiltersLoading, getCardDetailsLoading]);

  return (
    <div className="page-content">
      {loading && <Loader zIndex={true} />}
      <BookingModals
        currentSelectedBooking={currentSelectedBooking}
        currentActiveBookingAction={currentActiveBookingAction}
        setCurrentActiveBookingAction={setCurrentActiveBookingAction}
      />

      <Container fluid className="position-relative">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center">
            <BreadcrumbTrail
              title={statusFilters?.results?.find(status => status?.filterId === statusFilter)?.name || `${getCardDetails?.results?.name || ''}`}
              navigationby={breadcrumNavigationHandler}
              navigation={[`Airport Dashboard`, `${getCardDetails?.results?.name || ''}`]}
            />
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                <i className="ri-pencil-fill cursor-pointer"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start custom-dropdown">
                <DropdownItem>Tat 6 Hours</DropdownItem>
                <DropdownItem>Tat 12 Hours</DropdownItem>
                <DropdownItem>Tat 24 Hours</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          <Filter filterHandler={filterSubmitHandler} />
        </div>

        <StatusFilter statusFilters={statusFilters} appliedStatusFilter={statusFilter} updateURLParams={updateURLParams} />
        <DataViews
          data={bookingsList}
          updateURLParams={updateURLParams}
          skip={currentPageNumber}
          limit={rowsPerPage}
          setCurrentSelectedBooking={setCurrentSelectedBooking}
          selectedBooking={currentSelectedBooking}
          setCurrentActiveBookingAction={setCurrentActiveBookingAction}
          titleText={statusFilters?.results?.find(status => status?.filterId === statusFilter)?.name || `${getCardDetails?.results?.name || ''}`}
        />
        {bookingDetails && (
          <Summary
            details={bookingDetails}
            currentSelectedBooking={currentSelectedBooking}
            setCurrentSelectedBooking={setCurrentSelectedBooking}
            setCurrentActiveBookingAction={setCurrentActiveBookingAction}
          />
        )}
      </Container>
    </div>
  );
};

export default AirportTransferBookingV2;
