import { all, fork } from 'redux-saga/effects';

import assignVendorRemarksSaga from './Bookings/AirportTransfer/AssignVendorRemarksList/saga';
import cancelBookingByVendorSaga from './Bookings/AirportTransfer/CancelBookingByVendor/saga';
import assignVendorSaga from './Bookings/AirportTransfer/AssignVendor/saga';
import createChauffeurSaga from './Bookings/AirportTransfer/CreateChauffeur/saga';
import confirmBookingByVendorSaga from './Bookings/AirportTransfer/ConfirmBookingByVendor/saga';
import bookingAssignToMeSaga from './Bookings/AirportTransfer/AssignToMe/saga';
import getBookingsListSaga from './Bookings/AirportTransfer/GetBookingsList/saga';
import getCarsListOfVendorSaga from './Bookings/AirportTransfer/GetCarsListOfVendor/saga';
import assignChauffeurSaga from './Bookings/AirportTransfer/AssignChauffeur/saga';
import getFilterValuesSaga from './Bookings/AirportTransfer/GetFilterValues/saga';
import getFiltersListSaga from './Bookings/AirportTransfer/GetFiltersList/saga';
import cancellationRemarksForVendorSaga from './Bookings/AirportTransfer/CancellationRemarksForVendor/saga';
import getVendorsListSaga from './Bookings/AirportTransfer/GetVendorsList/saga';
import getChauffeursListSaga from './Bookings/AirportTransfer/GetChauffeursList/saga';
import getBookingDetailsSaga from './Bookings/AirportTransfer/GetBookingDetails/saga';
import watchBookingTypeSaga from './BookingType/saga';
import watchV2DashboardSaga from './Dashboard/saga';
import watchUserRolesSaga from './UserRole/saga';
import statusFiltersListSaga from './Bookings/AirportTransfer/StatusFiltersList/saga';
import getCardDetailsSaga from './Bookings/AirportTransfer/GetCardDetails/saga';
import cancelBookingByAdminSaga from './Bookings/AirportTransfer/CancelBookingByAdmin/saga';

export function* bookingsSagas() {
  yield all([
    fork(assignVendorRemarksSaga),
    fork(cancelBookingByVendorSaga),
    fork(assignVendorSaga),
    fork(createChauffeurSaga),
    fork(confirmBookingByVendorSaga),
    fork(bookingAssignToMeSaga),
    fork(getBookingsListSaga),
    fork(getCarsListOfVendorSaga),
    fork(assignChauffeurSaga),
    fork(getFilterValuesSaga),
    fork(getFiltersListSaga),
    fork(cancellationRemarksForVendorSaga),
    fork(getVendorsListSaga),
    fork(getChauffeursListSaga),
    fork(getBookingDetailsSaga),
    fork(watchBookingTypeSaga),
    fork(watchV2DashboardSaga),
    fork(watchUserRolesSaga),
    fork(statusFiltersListSaga),
    fork(getCardDetailsSaga),
    fork(cancelBookingByAdminSaga),
  ]);
}

export default bookingsSagas;
