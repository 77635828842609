import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constant } from '../../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, Col, Label, Input, Row } from 'reactstrap';

import { CONFIRM_BOOKING_GOLF } from '../../../../../../../../store/actions';
import { Error } from '../../../../../../../Common';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const ConfirmModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState({
    name: '',
    booking_id: '',
    confirmation_date_time: '',
    preferred_date_time1: '',
    preferred_date_time2: '',
  });

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  console.log('state', state);

  useEffect(() => {
    setState({
      name: data?.user?.firstName || data?.user?.lastName ? `${data?.user?.firstName || ''} ${data?.user?.lastName || ''}` : '',
      booking_id: data?.booking_id,
      confirmation_date_time: moment(`${data?.golf_booking_details?.date_of_service} ${data?.golf_booking_details?.time_of_service}`)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]'),
      preferred_date_time1: moment(
        `${data?.golf_booking_details?.preferred_date_of_service1} ${data?.golf_booking_details?.preferred_time_of_service1}`,
      )
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]'),
      preferred_date_time2: moment(
        `${data?.golf_booking_details?.preferred_date_of_service2} ${data?.golf_booking_details?.preferred_time_of_service2}`,
      )
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]'),
    });
  }, [data]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: CONFIRM_BOOKING_GOLF,
        payload: {
          data: {
            booking_id: state?.booking_id,
            service_id: state?.service_id,
            benefit_group_config_id: String(data?.benefit_group_config_id),
            facility_id: data?.facility_id,
            membership_id: data?.membership_id,
            golf: {
              confirmed_date_time: state.confirmation_date_time,
            },
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (state?.confirmation_date_time !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirm Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log({ data, state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={state?.booking_id} disabled />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={state.name} disabled />
                </div>
              </Col>
            </div>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="created-at-range" className="form-label">
                  Preferred Date & Time 1
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    disabled={true}
                    value={state?.preferred_date_time1}
                    options={{
                      dateFormat: 'Y-m-d H:i',
                      enableTime: true, // Enable time picker
                      time_24hr: true, // Use 24-hour format
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="created-at-range" className="form-label">
                  Preferred Date & Time 2
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    value={state?.preferred_date_time2}
                    disabled={true}
                    options={{
                      dateFormat: 'Y-m-d H:i',
                      enableTime: true, // Enable time picker
                      time_24hr: true, // Use 24-hour format
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="created-at-range" className="form-label">
                  Confirmation Date & Time
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    onChange={date => {
                      setState({ ...state, confirmation_date_time: moment(date[0]).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') });
                    }}
                    value={state?.confirmation_date_time}
                    options={{
                      minDate: 'today',
                      dateFormat: 'Y-m-d H:i',
                      enableTime: true, // Enable time picker
                      time_24hr: true, // Use 24-hour format
                    }}
                  />
                </div>
                {isValidationShow && state?.confirmation_date_time === `` && <Error text="Please select date" />}
              </div>
            </Col>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="date-of-play" className="form-label">
                    Confirmed Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      value={state?.bookingDateTime}
                      className="form-control"
                      type="time"
                      onChange={(e) => {
                        setState({
                          ...state,
                          bookingDateTime: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div> */}
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
