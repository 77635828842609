import React from 'react';
import CountUp from 'react-countup';
import { FaFire } from 'react-icons/fa';
import { Card, CardBody } from 'reactstrap';
import vendorAssign from '../../../../../assets/custom/v2/vendor.svg';

const StatusCard = ({ timelineBooking }) => {
  return (
    <Card
      style={{
        border: '1px solid #FFFFFF',
        borderRadius: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Added boxShadow
      }}
      className="sla-card"
    >
      <CardBody>
        <div className="d-flex justify-content-between">
          <div
          // className="icon-container"
          // style={{
          //   background: '#E62E3F1A',
          //   padding: '10px',
          //   borderRadius: '10px',
          //   height: '40px',
          // }}
          >
            <img src={timelineBooking?.data?.icon ? timelineBooking?.data?.icon : vendorAssign} className="img-fluid" alt="" />
          </div>
          <div>
            <p className="m-0" style={{ fontWeight: '500', fontSize: '15px', color: 'black' }}>
              {timelineBooking?.displayName}
            </p>
            <div style={{ textAlign: 'end' }}>
              <CountUp
                separator=""
                start={0}
                end={Number(timelineBooking?.data?.totalCount)}
                duration={2}
                style={{ color: 'black', fontSize: '25px', fontWeight: '500' }}
                className="fw-semibold"
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default StatusCard;
