import moment from 'moment';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import HotTimeClock from './../../../../../../../assets/custom/hot-time-clock.gif';
import WarningTimeClock from './../../../../../../../assets/custom/warning-time-clock.gif';
import SufficientTimeClock from './../../../../../../../assets/custom/sufficient-time-clock.gif';

const CardView = ({ data, selectedBooking, setCurrentSelectedBooking, setCurrentActiveBookingAction }) => {
  return (
    <div className="horizontal-scrollor">
      <div className="booking-data-card-container">
        {data?.map((result, index) => (
          <>
            <Card className={`booking-card ${selectedBooking?.bookingId === result?.bookingId ? 'active' : ''}`} key={index}>
              <CardHeader>
                <div className="booking-card-header">
                  <div className="booking-id-details">
                    <p>
                      Booking Id -{' '}
                      <span className="make-ellipsis-10ch" onClick={() => setCurrentSelectedBooking(result)}>
                        {result?.bookingId}
                      </span>
                    </p>
                  </div>
                  <div
                    className="booking-tat-details"
                    style={
                      ({ ...(result?.tat?.color === '#FCE2E2' && { backgroundColor: '#FCE2E2' }) },
                      { ...(result?.tat?.color === '#FFF5E7' && { backgroundColor: '#FFF5E7' }) },
                      { ...(result?.tat?.color === '#92a3dc' && { backgroundColor: '#92a3dc' }) })
                    }
                  >
                    {result?.tat?.color === '#FCE2E2' && <img src={HotTimeClock} className="remaining-clock-time" />}
                    {result?.tat?.color === '#FFF5E7' && <img src={WarningTimeClock} className="remaining-clock-time" />}
                    {result?.tat?.color === '#92a3dc' && <img src={SufficientTimeClock} className="remaining-clock-time" />}

                    <p> {`${result?.tat?.value} ${result?.tat?.unit} Remaining`}</p>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="booking-card-body">
                <Row>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Pick Up Location</div>
                    <div className="booking-details-data">{result?.pickupLocation}</div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Service Date & Time</div>
                    <div className="booking-details-data">
                      {result?.serviceDateTime ? `${moment(new Date(`${result?.serviceDateTime}`)).format('DD/MM/YYYY h:mm A')}` : 'NA'}
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Drop Location</div>
                    <div className="booking-details-data">{result?.dropLocation}</div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Created At</div>
                    <div className="booking-details-data">
                      {result?.serviceDateTime ? `${moment(new Date(`${result?.bookingCreatedAt}`)).format('DD/MM/YYYY')}` : 'NA'}
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Region</div>
                    <div className="booking-details-data">{result?.region}</div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Zone</div>
                    <div className="booking-details-data">{result?.zone}</div>
                  </Col>

                  <Col md={12} className="text-center mt-3 mb-5">
                    <div className="d-flex gap-2 flex-wrap justify-content-center">
                      {result?.actions?.includes(`ASSIGN_TO_ME`) && (
                        <Button
                          className="customized-btn"
                          color="primary"
                          onClick={() => {
                            setCurrentActiveBookingAction(`ASSIGN_TO_ME`);
                          }}
                        >
                          Assign to Me
                        </Button>
                      )}

                      {result?.actions?.includes(`ASSIGN_VENDOR`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            // alert('Assign to vendor');
                            setCurrentActiveBookingAction(`ASSIGN_VENDOR`);
                          }}
                        >
                          Assign to Vendor
                        </Button>
                      )}

                      {result?.actions?.includes(`RE_ASSIGN_VENDOR`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentActiveBookingAction(`RE_ASSIGN_VENDOR`);
                          }}
                        >
                          Reassign Vendor
                        </Button>
                      )}

                      {result?.actions?.includes(`ASSIGN_CHAUFFEUR`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentActiveBookingAction(`ASSIGN_CHAUFFEUR`);
                          }}
                        >
                          Assign Chauffeur
                        </Button>
                      )}

                      {result?.actions?.includes(`CHANGE_CHAUFFEUR`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentActiveBookingAction(`CHANGE_CHAUFFEUR`);
                          }}
                        >
                          Change Chauffeur
                        </Button>
                      )}

                      {/* {result?.actions?.includes(`CANCEL_BOOKING`) && (
                      <Button
                        className="customized-btn"
                        color="danger"
                        onClick={() => {
                          setCurrentActiveBookingAction(`CANCEL_BOOKING`);
                        }}
                      >
                        Cancel Booking
                      </Button>
                    )} */}
                      {result?.actions?.includes(`RAISE_DISPUTE`) && (
                        <Button
                          className="customized-btn"
                          color="danger"
                          onClick={() => {
                            setCurrentActiveBookingAction(`RAISE_DISPUTE`);
                          }}
                        >
                          Raise Dispute
                        </Button>
                      )}
                      {result?.actions?.includes(`VENDOR_CONFIRM`) && (
                        <Button
                          className="customized-btn"
                          color="primary"
                          onClick={() => {
                            setCurrentActiveBookingAction(`VENDOR_CONFIRM`);
                          }}
                        >
                          Vendor Confirm
                        </Button>
                      )}
                      {result?.actions?.includes(`VENDOR_DECLINE`) && (
                        <Button
                          className="customized-btn"
                          color="danger"
                          onClick={() => {
                            setCurrentActiveBookingAction(`VENDOR_DECLINE`);
                          }}
                        >
                          Vendor Decline
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        ))}
      </div>
    </div>
  );
};

export default CardView;
