import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PermissionConstants } from '../Containers/Helpers/Constants/permissions';
import { hasPermission } from '../Containers/Helpers/Helpers';

import { Constant } from '../Containers/Helpers/constant';

const NavdataV2 = () => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isDashboard, setIsDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach(item => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id)) document.getElementById(id).classList.remove('show');
      });
    }
  }

  /* ---------------------------- MENU ---------------------------- */
  let menuItems = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/v2/dashboard',
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsDashboard(!isDashboard);
        setIscurrentState('Dashboard');
      },
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default NavdataV2;
