const StatusFilter = ({ statusFilters, appliedStatusFilter, updateURLParams }) => {
  return (
    <>
      <div className="horizontal-scrollor">
        <div className="status-badge-container ">
          {statusFilters?.results?.map((filter, index) => {
            return (
              <div
                className={`status-badge ${filter?.filterId === appliedStatusFilter ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  updateURLParams({ statusFilter: filter?.filterId });
                }}
              >
                <p className="status-badge-text">
                  {filter?.name} ({filter?.bookingCount || 0})
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StatusFilter;
