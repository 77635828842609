import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import { Container, Row } from 'reactstrap';
import { GET_DASHBOARD, GET_DASHBOARD_DETAILS, GET_DASHBOARD_DETAILS_EMPTY } from '../../../../store/actions';
import { DonutChart, Loader } from '../../../Common';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import BookingCard from './components/Cards/BookingCard';
import BookingTimelineCard from './components/Cards/BookingTimelineCard';
import BookingTypeCard from './components/Cards/BookingTypeCard';
import TeamActivityCard from './components/Cards/TeamActivityCard';
import BarChart from './components/Charts/BarChart';

const autoUpdateOptions = [
  { value: '1', label: '1 Minute' },
  { value: '5', label: '5 Minute' },
  { value: '20', label: '20 Minute' },
  { value: '60', label: '60 Minute' },
  { value: 'Never', label: 'Never' },
];

interface RootState {
  V2Dashboard?: {
    data?: any;
    loading?: boolean;
  };
  V2DashboardDetails?: {
    data?: any;
    loading?: boolean;
  };
}

const DashboardV2 = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Store interval reference

  const [bookingTypeSectionTwo, setBookingTypeSectionTwo] = useState<any>([]);
  const [bookingTypes, setBookingTypes] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    teamActivity: null,
    bookingTimelineStats: null,
    bookingStats: null,
    projectWiseBookings: null,
    categoryWiseBooking: null,
  });
  const [selectedInterval, setSelectedInterval] = useState<string>('Never'); // Track selected interval
  const [selectedCard, setSelectedCard] = useState(null);

  const { getDashboard, getDashboardLoading } = useSelector((state: RootState) => ({
    getDashboard: state.V2Dashboard.data,
    getDashboardLoading: state.V2Dashboard.loading,
  }));

  const { getDashboardDetails, getDashboardDetailsLoading } = useSelector((state: RootState) => ({
    getDashboardDetails: state.V2DashboardDetails.data,
    getDashboardDetailsLoading: state.V2DashboardDetails.loading,
  }));

  // Auto-update logic
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear previous interval before setting a new one
    }

    if (selectedInterval !== 'Never') {
      intervalRef.current = setInterval(() => {
        getDashboardData();
        dispatch({
          type: GET_DASHBOARD_DETAILS_EMPTY,
        });
      }, Number(selectedInterval) * 60 * 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Cleanup when unmounting
      }
    };
  }, [selectedInterval]);

  useEffect(() => {
    getDashboardData();
  }, []);

  const reFetch = () => {
    getDashboardData();
  };

  useEffect(() => {
    if (getDashboard !== null) {
      const allCards = getDashboard?.sections.find((data: any) => data?.type === 'timeline_wise_bookings');
      const allChart = getDashboard?.sections.find((data: any) => data?.type === 'data_charts');

      const groupedCards = allCards?.cards.reduce((acc, card) => {
        if (!acc[card.cardType]) {
          acc[card.cardType] = [];
        }
        acc[card.cardType].push(card);
        return acc;
      }, {});
      const groupedCarts = allChart?.cards.reduce((acc, card) => {
        if (!acc[card.cardType]) {
          acc[card.cardType] = [];
        }
        acc[card.cardType].push(card);
        return acc;
      }, {});
      setData({
        teamActivity: groupedCards?.['TEAM_ACTIVITY_STATS'],
        bookingTimelineStats: groupedCards?.['BOOKING_TIMELINE_STATS'],
        bookingStats: groupedCards?.['BOOKING_STATS'],
        projectWiseBookings: groupedCarts?.['PIE_CHART'],
        categoryWiseBooking: groupedCarts?.['COLUMN_CHART'],
      });
    }
  }, [getDashboard]);

  useEffect(() => {
    if (getDashboardLoading || getDashboardDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getDashboardLoading, getDashboardDetailsLoading]);

  useEffect(() => {
    if (getDashboardDetails !== null) {
      setBookingTypeSectionTwo(getDashboardDetails?.sections?.[0]?.cards);
    }
  }, [getDashboardDetails]);

  const handleBookingTypeEvent = (item, index) => {
    setSelectedCard(index);
    dispatch({
      type: GET_DASHBOARD_DETAILS,
      payload: {
        urlParams: {
          id: item?.toggleAction?.entityId,
          entity: item?.toggleAction?.entityType,
          timelineFilter: item?.data?.timelineFilter,
        },
      },
    });
  };

  const getDashboardData = () => {
    dispatch({
      type: GET_DASHBOARD,
    });
  };

  const autoUpdateHandler = (selectedOption: any) => {
    setSelectedInterval(selectedOption.value);
  };

  const events = ['Vendr Assigned', 'Chauffeur Reassigned', 'Booking Modified', 'Booking Completed'];

  return (
    <>
      <div className="page-content">
        <Container fluid className="position-relative">
          {loading && <Loader />}
          <div className="d-flex  justify-content-between">
            <BreadcrumbTrail title={'Dashboard'} navigationby={() => {}} navigation={['Dashboard']} />
            <div className="d-flex gap-3">
              <div style={{ height: 40 }}>
                <Select
                  options={autoUpdateOptions}
                  onChange={autoUpdateHandler}
                  placeholder="Select Auto Update"
                  styles={{
                    control: provided => ({
                      ...provided,
                      width: '200px', // Set custom width
                    }),
                  }}
                />
              </div>
              <button style={{ height: 37 }} className="btn btn-success d-flex align-items-center gap-1" onClick={reFetch}>
                <i className=" ri-refresh-line"></i>
                Refresh
              </button>
            </div>
          </div>

          <div className="sla-container">
            <div className="sla-scroll">
              {data?.teamActivity && <TeamActivityCard item={data?.teamActivity?.[0]} />}
              {data?.bookingTimelineStats &&
                data.bookingTimelineStats.map((timeLineStatsBooking, index) => (
                  <BookingTimelineCard
                    key={index}
                    item={timeLineStatsBooking}
                    handleBookingType={() => handleBookingTypeEvent(timeLineStatsBooking, index)}
                    isSelected={selectedCard === index}
                  />
                ))}
              {data?.bookingStats && <BookingCard item={data?.bookingStats[0]} />}
            </div>
          </div>

          {bookingTypeSectionTwo.length > 0 && (
            <div className="card-5 w-100">
              <div className="d-flex align-items-end gap-2">
                <h4 className="text-black text-format-7 m-0">Booking Types</h4>
                <p className="mb-0 text-color-light-grey">Today {moment(new Date()).format('DD, MMM hh:mm:ss')}</p>
              </div>
              <div className="scrollable-1 mt-3">
                <div className="d-inline-flex gap-4">
                  {bookingTypeSectionTwo?.map((item, key) => (
                    <div className="card card-6 " key={key}>
                      <BookingTypeCard item={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <Row className="mt-4">
            {data?.projectWiseBookings && (
              <DonutChart
                lastUpdatedAt={data?.projectWiseBookings?.[0]?.lastUpdatedAt}
                data={data?.projectWiseBookings?.[0]?.data}
                title={'Project Wise Bookings'}
              />
            )}

            {data?.categoryWiseBooking && (
              <BarChart
                data={data?.categoryWiseBooking?.[0]?.data}
                title={'Category Day Wise Delayed Booking'}
                lastUpdatedAt={data?.projectWiseBookings?.[0]?.lastUpdatedAt}
              />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DashboardV2;
