import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { BoldCaretDown, CaretDown } from '../../../Components/icons';
import { CHANGE_ACTIVITY, LOGIN_V2_EMPTY } from '../../../store/actions';
import { Constant } from '../../Helpers/constant';
import { getUserV2Name } from '../../Helpers/Helpers';

export default function Header({ headerClass }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isUserStatusDropdown, setIsUserStatusDropdown] = useState(false);
  const [activeUserStatus, setActiveUserStatus] = useState('INACTIVE');
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const toggleUserStatusDropdown = () => {
    setIsUserStatusDropdown(!isUserStatusDropdown);
  };

  const profilePictureCreator = name => {
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');

    // Draw a circle
    context.beginPath();
    context.arc(50, 50, 40, 0, 2 * Math.PI);
    context.fillStyle = '#18ae73';
    context.fill();

    // Draw the first character of the name
    context.font = '40px Arial';
    context.fillStyle = '#fff';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(name?.charAt(0)?.toUpperCase(), 50, 50);

    return canvas.toDataURL();
  };

  const logoutHandler = () => {
    dispatch({
      type: LOGIN_V2_EMPTY,
    });
    const isAdminUserV2 = Number(localStorage.getItem('userType')) === Constant.userTypes.ADMIN_USER_V2;
    setTimeout(() => {
      localStorage.clear();
      isAdminUserV2 ? history.push(`/login-v2`) : history.push(`/v2/vendor/login`);
      window.location.reload();
    }, 200);
  };

  const userStatus = {
    ACTIVE: {
      name: 'Active',
      className: 'user-status-pointer-success',
    },
    INACTIVE: {
      name: 'Inactive',
      className: 'user-status-pointer-inactive',
    },
    ON_BREAK: {
      name: 'Out Break',
      className: 'user-status-pointer-out-break',
    },
  };

  const toggleUserStatus = status => {
    dispatch({
      type: CHANGE_ACTIVITY,
      payload: {
        data: {
          activityState: status,
        },
      },
    });
    setActiveUserStatus(status);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu');
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute('data-layout') === 'vertical') {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel');
    }
  };

  return (
    <header id="page-topbar" className={headerClass}>
      <div className="layout-width">
        <div className="navbar-header">
          <div className="container-fluid px-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-content-center">
                <button
                  onClick={toogleMenuBtn}
                  type="button"
                  className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                  id="topnav-hamburger-icon"
                >
                  <span className="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              </div>
              <div className="d-flex align-items-center">
                {Number(localStorage.getItem('userType')) === Constant.userTypes.ADMIN_USER_V2 && (
                  <div>
                    <Dropdown isOpen={isUserStatusDropdown} toggle={toggleUserStatusDropdown} className="ms-sm-3 header-item">
                      <DropdownToggle tag="button" type="button" className="btn">
                        <div className="user-status">
                          <div className={`user-status-pointer ${userStatus?.[activeUserStatus]?.className}`} />
                          {userStatus?.[activeUserStatus]?.name} <CaretDown />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {Object.keys(userStatus).map((item, i) => (
                          <div key={i}>
                            {i > 0 && <DropdownItem divider />}
                            <DropdownItem onClick={() => toggleUserStatus(item)}>
                              <span className="align-middle d-flex align-items-center gap-2" data-key={item}>
                                <div className={`user-status-pointer ${userStatus?.[item]?.className}`} />
                                {userStatus?.[item]?.name}
                              </span>
                            </DropdownItem>
                          </div>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                <div>
                  <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item">
                    <DropdownToggle tag="button" type="button" className="btn">
                      <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user-2" src={profilePictureCreator(getUserV2Name())} alt="" />
                        <span className="text-start ms-xl-2">
                          <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text user-name-text-2">{getUserV2Name()}</span>
                        </span>

                        <span>
                          <BoldCaretDown />
                        </span>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem>
                        <span className="align-middle" data-key="t-profile">
                          My Profile
                        </span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <span className="align-middle" data-key="t-change-role">
                          Change Role
                        </span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={logoutHandler}>
                        <span className="align-middle" data-key="t-logout">
                          Log out
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
