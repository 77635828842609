import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ASSIGN_VENDOR_REMARKS_LIST,
  BOOKING_ASSIGN_TO_ME,
  CANCELLATION_REMARKS_FOR_VENDOR,
  GET_CARS_LIST_OF_VENDOR,
  GET_CHAUFFEURS_LIST,
  GET_VENDORS_LIST_DATA,
} from '../../../../../../../store/actions';
import AssignChauffeur from './AssignChauffeur';
import AssignVendor from './AssignVendor';
import BookingConfirm from './BookingConfirm';
import CancelBooking from './CancelBooking';
import DeclineBooking from './DeclineBooking';
import LoaderData from './LoaderData';

const BookingModals = ({ currentSelectedBooking, currentActiveBookingAction, setCurrentActiveBookingAction }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATE ---------------------------- */
  const [isShowCancelBooking, setIsShowCancelBooking] = useState(false);
  const [isShowDeclineBooking, setIsShowDeclineBooking] = useState(false);
  const [isShowAssignChauffeur, setIsShowAssignChauffeur] = useState(false);
  const [isShowAssignVendor, setIsShowAssignVendor] = useState(false);
  const [isShowVendorConfirmBooking, setIsShowVendorConfirmBooking] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { assignVendorRemarks, cancellationRemarks, carsListOfVendor, chauffeursList, vendorsList } = useSelector((state: any) => ({
    assignVendorRemarks: state?.AssignVendorRemarksList?.data,
    cancellationRemarks: state?.CancellationRemarksForVendor?.data,
    carsListOfVendor: state?.GetCarsListOfVendor?.data,
    chauffeursList: state?.GetChauffersList?.data,
    vendorsList: state?.GetVendorsListData?.data,
  }));

  useEffect(() => {
    if (assignVendorRemarks === null) {
      dispatch({
        type: ASSIGN_VENDOR_REMARKS_LIST,
      });
    }

    if (cancellationRemarks === null) {
      dispatch({
        type: CANCELLATION_REMARKS_FOR_VENDOR,
        payload: {
          params: {
            // entity: entity,
            isVendorCancel: true,
          },
        },
      });
    }

    if (carsListOfVendor === null) {
      dispatch({
        type: GET_CARS_LIST_OF_VENDOR,
      });
    }

    if (chauffeursList === null) {
      dispatch({
        type: GET_CHAUFFEURS_LIST,
      });
    }

    if (vendorsList === null) {
      dispatch({
        type: GET_VENDORS_LIST_DATA,
      });
    }
  }, []);

  useEffect(() => {
    if (currentActiveBookingAction !== null) {
      switch (currentActiveBookingAction) {
        case `ASSIGN_TO_ME`:
          AssignToMe();
          break;

        case `ASSIGN_CHAUFFEUR`:
          setIsShowAssignChauffeur(true);
          break;

        case `CHANGE_CHAUFFEUR`:
          setIsShowAssignChauffeur(true);
          break;

        case `ASSIGN_VENDOR`:
          setIsShowAssignVendor(true);
          break;

        case `RE_ASSIGN_VENDOR`:
          setIsShowAssignVendor(true);
          break;

        case `CANCEL_BOOKING`:
          setIsShowCancelBooking(true);
          break;

        case `VENDOR_DECLINE`:
          setIsShowDeclineBooking(true);
          break;

        case `VENDOR_CONFIRM`:
          setIsShowVendorConfirmBooking(true);
          break;

        default:
        // No need to do anything
      }
    }
  }, [currentActiveBookingAction]);

  const AssignToMe = () => {
    dispatch({
      type: BOOKING_ASSIGN_TO_ME,
      payload: {
        params: {
          bookingId: currentSelectedBooking?.bookingId,
        },
      },
    });
  };

  return (
    <>
      <LoaderData setCurrentActiveBookingAction={setCurrentActiveBookingAction} />

      {isShowAssignChauffeur && (
        <AssignChauffeur
          carsListOfVendor={carsListOfVendor}
          chauffeursList={chauffeursList}
          currentSelectedBooking={currentSelectedBooking}
          onClose={() => {
            setIsShowAssignChauffeur(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowAssignVendor && (
        <AssignVendor
          assignVendorRemarks={assignVendorRemarks}
          vendorsList={vendorsList}
          currentSelectedBooking={currentSelectedBooking}
          onClose={() => {
            setIsShowAssignVendor(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowCancelBooking && (
        <CancelBooking
          currentSelectedBooking={currentSelectedBooking}
          remarksList={cancellationRemarks}
          onClose={() => {
            setIsShowCancelBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}
      {isShowDeclineBooking && (
        <DeclineBooking
          currentSelectedBooking={currentSelectedBooking}
          remarksList={cancellationRemarks}
          onClose={() => {
            setIsShowDeclineBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}

      {isShowVendorConfirmBooking && (
        <BookingConfirm
          currentSelectedBooking={currentSelectedBooking}
          onClose={() => {
            setIsShowVendorConfirmBooking(false);
            setCurrentActiveBookingAction(null);
          }}
        />
      )}
    </>
  );
};

export default BookingModals;
