import { useEffect, useState } from 'react';

import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Input } from 'reactstrap';
import HotClock from './../../../../../../../assets/custom/hot-clock.png';
import SufficientClock from './../../../../../../../assets/custom/sufficient-clock.png';
import WarningClock from './../../../../../../../assets/custom/warning-clock.png';
import CustomizedTable from './CustomizedTable';

const TabularView = ({
  data,
  totalCount,
  skip,
  limit,
  handleRowsPerPageChange,
  handlePageChange,
  actionBtnHandler,
  setCurrentSelectedBooking,
  selectedBooking,
  isTableDisplay,
}) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingsData, setBookingsData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const fetchColumns = [
        {
          name: '',
          width: '70px',
          wrap: true,
          selector: row => row?.serial_number,
          cell: row => {
            return (
              <Input
                className="form-check-input cursor-pointer "
                type="checkbox"
                id="selected-booking"
                name="selected-booking"
                checked={row?.bookingId === selectedBooking?.bookingId}
                onChange={() => setCurrentSelectedBooking(row?.originalData)}
              />
            );
          },
        },
        {
          name: 'S.No',
          width: '70px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Booking ID',
          width: '130px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => setCurrentSelectedBooking(row?.originalData)}
            >
              {row?.bookingId}
            </span>
          ),
        },
        {
          name: 'TAT',
          width: '230px',
          cell: row => (
            <div className="d-flex align-items-center gap-1">
              {row?.tat?.color === '#FCE2E2' && <img src={HotClock} className="tabular-remaining-clock" />}
              {row?.tat?.color === '#FFF5E7' && <img src={WarningClock} className="tabular-remaining-clock" />}
              {row?.tat?.color === '#92a3dc' && <img src={SufficientClock} className="tabular-remaining-clock" />}

              <span>{`${row?.tat?.value} ${row?.tat?.unit} Remaining`}</span>
            </div>
          ),
        },
        {
          name: 'Service Date & Time',
          width: '180px',
          cell: row => row?.serviceDateTime,
        },
        {
          name: 'Region',
          width: '150px',
          cell: row => row?.region,
        },
        {
          name: 'Zone',
          width: '150px',
          cell: row => row?.zone,
        },
        {
          name: 'Pick Up Location',
          width: '250px',
          cell: row => row?.pickupLocation,
        },
        {
          name: 'Drop Location',
          width: '250px',
          cell: row => row?.dropLocation,
        },
      ];
      setColumns(fetchColumns);

      setBookingsData(
        data?.map((bookingData, index) => {
          return {
            serial_number: `${limit * skip + (index + 1)}`,
            ...bookingData,
            serviceDateTime: bookingData?.serviceDateTime
              ? `${moment(new Date(`${bookingData?.serviceDateTime}`)).format('DD-MM-YYYY, h:mm A')}`
              : 'NA',

            originalData: bookingData,
          };
        }),
      );
    } else {
      setBookingsData([]);
      setColumns([]);
    }
  }, [data, selectedBooking]);

  return (
    <CustomizedTable
      columns={columns ?? []}
      data={bookingsData ?? []}
      totalCount={totalCount}
      limit={limit}
      handleRowsPerPageChange={handleRowsPerPageChange}
      currentPage={skip}
      handlePageChange={handlePageChange}
      indexType={Constant.INDEX_TYPE.ZERO_BASED}
      isTableDisplay={isTableDisplay}
    />
  );
};

export default TabularView;
