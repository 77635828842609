import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { GET_CHAUFFEURS_LIST, GET_CHAUFFEURS_LIST_ERROR, GET_CHAUFFEURS_LIST_SUCCESS } from '../../../actions.js';

function* getChauffeursList({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/chauffeurs?vendor_id=1&booking_id=2`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_CHAUFFEURS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CHAUFFEURS_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CHAUFFEURS_LIST_ERROR, payload: error });
  }
}

export function* watchGetChauffeursList() {
  yield takeEvery(GET_CHAUFFEURS_LIST, getChauffeursList);
}

function* getChauffeursListSaga() {
  yield all([fork(watchGetChauffeursList)]);
}

export default getChauffeursListSaga;
