import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from 'moment';
ChartJS.register(ArcElement, Tooltip, Legend);

const BarChart = ({ data, title, lastUpdatedAt }) => {
  const [selectedItems, setSelectedItems] = useState(data.distributions);
  const [labels, setLabels] = useState([]);
  const [categoryByData, setCategoryByData] = useState([]);

  // Toggle legend selection (remove/add item)
  const toggleLegend = item => {
    setSelectedItems(prev => {
      const isRemoved = prev.some(d => d.label === item.label);
      return isRemoved ? prev.filter(d => d.label !== item.label) : [...prev, item];
    });
  };

  const getTimeAgoText = (isoDate: string): string => {
    const date = moment(isoDate);
    const now = moment();

    if (date.isSame(now, 'day')) {
      const diffHours = now.diff(date, 'hours');
      console.log('diffHours', diffHours);
      if (diffHours < 1) {
        return 'Last Updated Just now';
      } else if (diffHours < 24) {
        return `Last Updated ${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
      } else {
        return 'Last Updated Today';
      }
    } else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return 'Last Updated Yesterday';
    } else if (date.isBetween(now.clone().subtract(7, 'days'), now, 'day', '[]')) {
      return 'Last Updated 7 Days';
    } else {
      return `Last Updated ${date.format('DD MMM YYYY')}`; // Example: "30 Jan 2025, 06:30 PM"
    }
  };

  useEffect(() => {
    const labelsData = [];
    const categoryByDataMapped: any = [];
    data?.distributions.map((dateViseItem: any) => {
      labelsData.push(moment(dateViseItem?.date).format('DD MMM'));
      dateViseItem?.categories.map((categoryItem: any) => {
        const existLabel = categoryByDataMapped.find(existValue => existValue?.label == categoryItem?.name);
        if (!existLabel) {
          categoryByDataMapped.push({
            label: categoryItem?.name,
            data: [categoryItem?.value],
            backgroundColor: categoryItem?.color,
            barThickness: 40,
          });
        } else {
          categoryByDataMapped.map((value1: any) => {
            if (value1?.label == categoryItem?.name) {
              value1.data.push(categoryItem?.value);
            }
          });
        }
      });
    });
    setLabels(labelsData);
    setSelectedItems(categoryByDataMapped);
    setCategoryByData(categoryByDataMapped);
  }, []);

  const BarData = {
    labels: labels,
    datasets: selectedItems,
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide default legend to make custom legends
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <Col xl={6} md={12} lg={6} className="mb-2" style={{ borderRadius: '10px' }}>
      <Card>
        <div className="d-flex justify-content-between m-4">
          <h4 className="card-title mb-0 flex-grow-1 fw-semibold">{title}</h4>
          <p className="text-muted">{getTimeAgoText(lastUpdatedAt)}</p>
        </div>
        <CardBody style={{ maxHeight: '400px', position: 'relative', textAlign: 'center' }}>
          <div style={{ height: '300px', position: 'relative' }}>
            <Bar data={BarData} options={options} />
          </div>
          {/* Custom Legends */}
          <div className="d-flex flex-wrap justify-content-center mt-3">
            {categoryByData.map(item => {
              const isSelected = selectedItems.some(d => d.label === item.label);
              return (
                <div
                  key={item.label}
                  onClick={() => toggleLegend(item)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '3px',
                    padding: '5px 10px',
                    backgroundColor: '#F2F2F7',
                    color: 'black',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {/* Square Color Box */}
                  <div
                    style={{
                      width: '13px',
                      height: '13px',
                      backgroundColor: item.backgroundColor,
                      borderRadius: '3px',
                      marginRight: '8px',
                      border: '1px solid #ccc',
                      fontSize: 10,
                    }}
                  ></div>
                  <span style={{ textDecoration: !isSelected ? 'line-through' : 'none', fontWeight: '400', fontSize: 12 }}>{item.label}</span>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BarChart;
