import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { InactiveUserIcon, TeamActivityIndicatorIcon, UserFrameIcon, UsersOnBreakIcon } from '../../../../../../Components/icons';
import moment from 'moment';
import CountUp from 'react-countup';

const TeamActivityCard = ({ item }) => {
  return (
    <Card style={{ borderRadius: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 2px 0px' }} className="sla-card">
      <CardBody style={{ padding: '0.3rem 0.7rem 1rem 1rem' }}>
        <p className="mb-0 text-color-light-grey text-end m-0">Today {moment(new Date()).format('DD, MMM hh:mm:ss')}</p>
        <div className="d-flex justify-content-between">
          <div>
            <UserFrameIcon />
          </div>
          <div className="text-end">
            <p className="text-black text-format-7 m-0">{item?.displayName}</p>
            <CountUp separator="" start={0} end={Number(item?.data?.totalCount)} duration={2} className="text-format-3 m-0 fw-bold" />
          </div>
        </div>

        <div className="d-flex">
          <div className="d-flex justify-content-between w-100">
            <div className="flex-2">
              <span>
                <TeamActivityIndicatorIcon />
              </span>
              {item?.data?.usersActive && <span className="text-format-1">{item?.data?.usersActive}</span>}
            </div>

            <div className="flex-2">
              <span>
                <InactiveUserIcon />
              </span>
              {item?.data?.usersInactive && <span className="text-format-2">{item?.data?.usersInactive}</span>}
            </div>

            <div className="flex-2">
              <span>
                <UsersOnBreakIcon />
              </span>
              {item?.data?.usersOnBreak && <span className="text-format-2">{item?.data?.usersOnBreak}</span>}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TeamActivityCard;
