import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { ASSIGN_VENDOR, ASSIGN_VENDOR_EMPTY } from '../../../../../../../store/actions';
import { Error } from '../../../../../../Common';
import AvailabilityTick from './../../../../../../../assets/custom/available-tick.png';
import SelectedItem from './../../../../../../../assets/custom/selected-item.png';

const AssignVendor = ({ onClose, currentSelectedBooking, assignVendorRemarks, vendorsList }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [selectedRemark, setSelectedRemark] = useState(null);
  const [currentSelectedVendor, setCurrentSelectedVendor] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { assignVendor, assignVendorLoading } = useSelector(state => ({
    assignVendor: state?.AssignVendor?.data,
    assignVendorLoading: state?.AssignVendor?.loading,
  }));

  useEffect(() => {
    if (assignVendor !== null) {
      dispatch({
        type: ASSIGN_VENDOR_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: ASSIGN_VENDOR_EMPTY,
      });
    };
  }, [assignVendor]);

  useEffect(() => {
    if (vendorsList?.vendors?.length > 0) {
      const activeVendor = vendorsList?.vendors?.find(vendors => vendors?.isSystemPreffered)?.id || null;
      setCurrentSelectedVendor(activeVendor);
    }
  }, [vendorsList]);

  const assignVendorToTheBooking = () => {
    const systemPrefferedVendor = vendorsList?.vendors?.find(vendors => vendors?.isSystemPreffered)?.id || null;

    if (systemPrefferedVendor === currentSelectedVendor) {
      dispatch({
        type: ASSIGN_VENDOR,
        payload: {
          body: {
            vendor_id: currentSelectedVendor,
            booking_id: currentSelectedBooking?.bookingId,
            ...(selectedRemark === null ? {} : { reason_id: selectedRemark?.value }),
          },
        },
      });
    } else {
      if (selectedRemark === null) {
        setIsValidationShow(true);
      } else {
        dispatch({
          type: ASSIGN_VENDOR,
          payload: {
            body: {
              vendor_id: currentSelectedVendor,
              booking_id: currentSelectedBooking?.bookingId,
              ...(selectedRemark === null ? {} : { reason_id: selectedRemark?.value }),
            },
          },
        });
      }
    }
  };

  return (
    <Modal isOpen={true} centered size="lg">
      <ModalHeader toggle={onClose}>
        <h4 className="assign-vendor-title">
          Assign to Vendor - <span className="vendor-booking-id"> Booking ID: {currentSelectedBooking?.bookingId}</span>
        </h4>
      </ModalHeader>

      <ModalBody className="vendor-selection-body">
        <div className="vendor-main-div">
          {vendorsList?.vendors.map((vendor, index) => (
            <div key={index} className={`vendor-cars ${vendor?.id === currentSelectedVendor ? 'active-vendor' : ''}`}>
              <div className="vendor-name">{vendor?.name}</div>

              {vendor?.id === currentSelectedVendor && (
                <div className="selected-vendor-container">
                  <img src={SelectedItem} className="selected-vendor" />
                </div>
              )}

              <div className="tag-container">
                <p className="tag-label">{vendor?.tag}</p>
              </div>

              <div className="vendor-core-details">
                <div className="core-detail">
                  <img src={AvailabilityTick} className="availability-tick" />
                  <p>Distance - {`${vendor?.distance}`}</p>
                </div>
                <div className="core-detail">
                  <img src={AvailabilityTick} className="availability-tick" />
                  <p>Zone - {`${vendor?.zone}`}</p>
                </div>
              </div>

              <h4 className="vendor-price">
                {vendor?.currency || ''} {vendor?.price || ''}
              </h4>

              {vendor?.id === currentSelectedVendor ? (
                <Button className="cta-select-vendor" color="primary">
                  Selected
                </Button>
              ) : (
                <Button
                  className="cta-select-vendor active"
                  onClick={() => {
                    setCurrentSelectedVendor(vendor?.id);
                    setSelectedRemark(null);
                  }}
                >
                  Choose
                </Button>
              )}
            </div>
          ))}
        </div>

        <Row className="mt-4">
          <Col md={8}>
            {(vendorsList?.vendors?.find(vendors => vendors?.isSystemPreffered)?.id || null) !== currentSelectedVendor && (
              <div>
                <label>Remarks</label>
                <Select
                  options={assignVendorRemarks?.remarks?.map(remark => ({
                    label: `${remark?.name}`,
                    value: `${remark?.id}`,
                  }))}
                  onChange={option => {
                    setSelectedRemark(option);
                    setIsValidationShow(false);
                  }}
                />
                {isValidationShow && selectedRemark === null && <Error text="Please select reason" />}
              </div>
            )}
          </Col>

          <Col md={4} className="text-end" style={{ alignSelf: 'end' }}>
            {!assignVendorLoading && (
              <Button color="primary" onClick={assignVendorToTheBooking} style={{ width: '150px' }}>
                Assign to Vendor
              </Button>
            )}

            {assignVendorLoading && (
              <Button color="primary" style={{ width: '150px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AssignVendor;
