import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GET_BOOKING_DETAILS_EMPTY } from '../../../../../../../store/actions';
import { Constant } from '../../../../../../Helpers/constant';
import CardViewActiveImg from './../../../../../../../assets/custom/card-view-active.png';
import CardViewImg from './../../../../../../../assets/custom/card-view.png';
import TabularViewActiveImg from './../../../../../../../assets/custom/tabular-view-active.png';
import TabularViewImg from './../../../../../../../assets/custom/tabular-view.png';
import CardView from './CardView';
import TabularView from './TabularView';

const DataViews = ({ data, titleText, updateURLParams, skip, limit, setCurrentSelectedBooking, selectedBooking, setCurrentActiveBookingAction }) => {
  const dispatch = useDispatch();
  /* ---------------------------- LOCAL STATE ---------------------------- */
  const [isTabularView, setIsTabularView] = useState(true);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = (label, data) => {
    console.log('tableActionHandler', label, data);
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ skip: 1, limit: newRowsPerPage });
  };

  const paginationHandler = pageNumber => {
    updateURLParams({ skip: pageNumber + 1, limit: limit });
  };

  const toggleView = () => {
    setIsTabularView(!isTabularView);
    updateURLParams({ skip: 1, limit: Constant?.TABLE_PAGE_SIZE });
    dispatch({
      type: GET_BOOKING_DETAILS_EMPTY,
    });
    setCurrentSelectedBooking(null);
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1 custom-card-title"> {titleText} </h4>

              <div className="d-flex gap-3">
                {isTabularView && (
                  <div className="d-flex gap-2">
                    {/* <>
                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`ASSIGN_TO_ME`);
                        }}
                        color="primary"
                      >
                        Assign to Me
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`ASSIGN_CHAUFFEUR`);
                        }}
                        color="success"
                      >
                        Assign Chauffeur
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`CHANGE_CHAUFFEUR`);
                        }}
                        color="success"
                      >
                        Change Chauffeur
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`ASSIGN_VENDOR`);
                        }}
                        color="success"
                      >
                        Assign to Vendor
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`RE_ASSIGN_VENDOR`);
                        }}
                        color="primary"
                      >
                        Reassign Vendor
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`CANCEL_BOOKING`);
                        }}
                        color="danger"
                      >
                        Cancel Booking
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`VENDOR_CONFIRM`);
                        }}
                        color="danger"
                      >
                        Vendor Confirm
                      </Button>

                      <Button
                        onClick={() => {
                          setCurrentActiveBookingAction(`VENDOR_DECLINE`);
                        }}
                        color="danger"
                      >
                        Vendor Decline
                      </Button>
                    </> */}

                    <>
                      {selectedBooking?.actions?.includes(`ASSIGN_TO_ME`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`ASSIGN_TO_ME`);
                          }}
                          color="primary"
                        >
                          Assign to Me
                        </Button>
                      )}
                      {selectedBooking?.actions?.includes(`ASSIGN_VENDOR`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`ASSIGN_VENDOR`);
                          }}
                          color="success"
                        >
                          Assign to Vendor
                        </Button>
                      )}

                      {selectedBooking?.actions?.includes(`RE_ASSIGN_VENDOR`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`RE_ASSIGN_VENDOR`);
                          }}
                          color="primary"
                        >
                          Reassign Vendor
                        </Button>
                      )}

                      {selectedBooking?.actions?.includes(`ASSIGN_CHAUFFEUR`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`ASSIGN_CHAUFFEUR`);
                          }}
                          color="success"
                        >
                          Assign Chauffeur
                        </Button>
                      )}

                      {selectedBooking?.actions?.includes(`CHANGE_CHAUFFEUR`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`CHANGE_CHAUFFEUR`);
                          }}
                          color="success"
                        >
                          Change Chauffeur
                        </Button>
                      )}

                      {/* {selectedBooking?.actions?.includes(`CANCEL_BOOKING`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`CANCEL_BOOKING`);
                          }}
                          color="danger"
                        >
                          Cancel Booking
                        </Button>
                      )} */}
                      {selectedBooking?.actions?.includes(`RAISE_DISPUTE`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`RAISE_DISPUTE`);
                          }}
                          color="danger"
                        >
                          Raise Dispute
                        </Button>
                      )}
                      {selectedBooking?.actions?.includes(`VENDOR_CONFIRM`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`VENDOR_CONFIRM`);
                          }}
                          color="danger"
                        >
                          Vendor Confirm
                        </Button>
                      )}
                      {selectedBooking?.actions?.includes(`VENDOR_DECLINE`) && (
                        <Button
                          className="customized-btn"
                          onClick={() => {
                            setCurrentActiveBookingAction(`VENDOR_DECLINE`);
                          }}
                          color="danger"
                        >
                          Vendor Decline
                        </Button>
                      )}
                    </>
                  </div>
                )}

                {/* {data?.results?.length > 0 && ( */}
                <div className="d-flex gap-2 align-items-center">
                  {isTabularView ? (
                    <img src={CardViewImg} className="view-img" onClick={toggleView} />
                  ) : (
                    <img src={CardViewActiveImg} className="view-img" onClick={toggleView} />
                  )}
                  {isTabularView ? (
                    <img src={TabularViewActiveImg} className="view-img" onClick={toggleView} />
                  ) : (
                    <img src={TabularViewImg} className="view-img" onClick={toggleView} />
                  )}
                </div>
                {/* )} */}
              </div>
            </CardHeader>

            <CardBody>
              {isTabularView ? (
                <TabularView
                  data={data?.results}
                  totalCount={data?.totalCount}
                  skip={skip}
                  limit={limit}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handlePageChange={paginationHandler}
                  actionBtnHandler={tableActionHandler}
                  setCurrentSelectedBooking={setCurrentSelectedBooking}
                  selectedBooking={selectedBooking}
                  isTableDisplay={true}
                />
              ) : (
                <>
                  <CardView
                    data={data?.results}
                    selectedBooking={selectedBooking}
                    setCurrentSelectedBooking={setCurrentSelectedBooking}
                    setCurrentActiveBookingAction={setCurrentActiveBookingAction}
                  />
                  <TabularView
                    data={data?.results}
                    totalCount={data?.count}
                    skip={skip}
                    limit={limit}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    handlePageChange={paginationHandler}
                    actionBtnHandler={tableActionHandler}
                    setCurrentSelectedBooking={setCurrentSelectedBooking}
                    isTableDisplay={false}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataViews;

// 1. ASSIGN_TO_ME
// 2. ASSIGN_VENDOR
// 3. VENDOR_CONFIRM
// 4. VENDOR_DECLINE
// 5. ASSIGN_CHAUFFEUR
// 6. RE_ASSIGN_VENDOR
// 7. CHANGE_CHAUFFEUR
// 8. RAISE_DISPUTE
// 9. CANCEL_BOOKING
