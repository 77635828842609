import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { CREATE_UPLOAD_TICKET, CREATE_UPLOAD_TICKET_SUCCESS, CREATE_UPLOAD_TICKET_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* createUploadTicket({ payload }) {
  const isClientUser = Constant.userTypes.CLIENT === Number(localStorage.getItem('userType'));

  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/${isClientUser ? 'client' : 'admin'}/upload-file`,
      method: 'POST',
      isFormData: true,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_UPLOAD_TICKET_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_UPLOAD_TICKET_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_UPLOAD_TICKET_ERROR, payload: error });
  }
}

export function* watchCreateUploadTicket() {
  yield takeEvery(CREATE_UPLOAD_TICKET, createUploadTicket);
}

function* createUploadTicketSaga() {
  yield all([fork(watchCreateUploadTicket)]);
}

export default createUploadTicketSaga;
