import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import { useHistory, useLocation } from 'react-router-dom';
import SLACard from './Components/Card/SLACard';
import moment from 'moment';
import StatusCard from './Components/Card/StatusCard';
import ActionBookingCard from './Components/Card/ActionBookingCard';
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Select from 'react-select';
import { DonutChart, GeoChart, Loader } from '../../../Common';
import { useDispatch, useSelector } from 'react-redux';
import { GET_DASHBOARD } from '../../../../store/actions';
import queryString from 'query-string';
import cogoToast from 'cogo-toast';

const initialDate = {
  startDate: '',
  endDate: '',
  timelineFilter: null,
};

const bookingOptions = [
  { value: 'past', label: 'Past Booking' },
  { value: 'ongoing', label: 'Ongoing Booking' },
  { value: 'upcoming', label: 'Upcoming Booking' },
];

interface RootState {
  V2Dashboard?: {
    data?: any;
    loading?: boolean;
  };
}

const BookingDashboard = () => {
  const inputRef = useRef(null);
  const flatpickrInstance = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { entity, entityId, timelineFilter } = queryString.parse(location.search);
  const selectedFilterType = bookingOptions.find(option => option.value === timelineFilter) || null;

  const [filter, setFilter] = useState({ ...initialDate, timelineFilter: selectedFilterType });
  const [laoding, setLaoding] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const { getDashboard, getDashboardLoading } = useSelector((state: RootState) => ({
    getDashboard: state.V2Dashboard.data,
    getDashboardLoading: state.V2Dashboard.loading,
  }));

  const [data, setData] = useState({
    slaWiseBookings: null,
    statusWiseBookings: null,
    actionTypeBookings: null,
    regionWiseChart: null,
    projectWiseBookings: null,
    pickAndDrops: null,
  });

  useEffect(() => {
    flatpickrInstance.current = Flatpickr(inputRef.current, {
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
      clickOpens: false, // Prevents default click to open
      defaultDate: [initialDate?.startDate, initialDate.endDate],
      onOpen: () => {
        setTimeout(() => {
          const calendarElement = document.querySelector('.flatpickr-calendar') as HTMLElement;
          const calendarElementHeader = document.querySelector('.flatpickr-current-month') as HTMLElement;
          const calendarIconPrev = document.querySelector('.flatpickr-prev-month') as HTMLElement;
          const calendarIconNext = document.querySelector('.flatpickr-next-month') as HTMLElement;

          if (calendarElement) {
            calendarElement.style.top = '4%';
            calendarElement.style.left = '35%';
            calendarElement.style.right = 'auto';
          }
          if (calendarElementHeader) {
            calendarElementHeader.style.color = 'white';
            calendarElementHeader.style.padding = '3px';
          }
          if (calendarIconPrev) {
            calendarIconPrev.style.fill = 'white'; // SVG elements use `setAttribute`
            calendarIconPrev.style.top = '-3px';
          }
          if (calendarIconNext) {
            calendarIconNext.style.fill = 'white';
            calendarIconNext.style.top = '-3px';
          }
        }, 0); // Ensures the calendar has been rendered before applying styles
      },
      onChange: date => {
        if (date[0] && date[1]) {
          setFilter({
            timelineFilter: null,
            startDate: moment(new Date(date[0])).format('Y-MM-DD'),
            endDate: moment(new Date(date[1])).format('Y-MM-DD'),
          });
          updateURLParams();
        }
      },
    });
  }, []);

  useEffect(() => {
    if (getDashboard !== null) {
      const isSLADisplay = getDashboard?.sections?.find(data => data?.type === 'sla_wise_bookings');
      const isStatusDisplay = getDashboard?.sections?.find(data => data?.type === 'status_wise_bookings');
      const isActionDisplay = getDashboard?.sections?.find(data => data?.type === 'action_type_bookings');
      const isChartDisplay = getDashboard?.sections?.find(data => data?.type === 'data_charts');
      const isRegionWiseChartDisplay = isChartDisplay?.cards?.find(chart => chart?.displayName === 'Region Wise Bookings');
      const isProjectWiseChartDisplay = isChartDisplay?.cards?.find(chart => chart?.displayName === 'Pickup Vs Drops');
      const isPickAndDropsDisplay = isChartDisplay?.cards?.find(chart => chart?.displayName === 'Project Wise Bookings');
      setData({
        slaWiseBookings: isSLADisplay,
        statusWiseBookings: isStatusDisplay,
        actionTypeBookings: isActionDisplay,
        regionWiseChart: isRegionWiseChartDisplay,
        projectWiseBookings: isProjectWiseChartDisplay,
        pickAndDrops: isPickAndDropsDisplay,
      });
    }
  }, [getDashboard]);

  useEffect(() => {
    if (!filter?.startDate && !filter?.endDate) {
      const currentDate = moment();
      let startDate = '';
      let endDate = '';

      switch (filter.timelineFilter?.value) {
        case 'upcoming':
          startDate = currentDate.clone().add(2, 'days').format('YYYY-MM-DD');
          endDate = currentDate.clone().add(30, 'days').format('YYYY-MM-DD'); // 30 days after start date
          break;

        case 'ongoing':
          startDate = currentDate.clone().subtract(2, 'days').format('YYYY-MM-DD');
          endDate = currentDate.clone().add(2, 'days').format('YYYY-MM-DD');
          break;

        case 'past':
          startDate = currentDate.clone().subtract(3, 'months').subtract(2, 'days').format('YYYY-MM-DD');
          endDate = currentDate.clone().subtract(2, 'days').format('YYYY-MM-DD');
          break;

        default:
          startDate = '';
          endDate = '';
      }

      setFilter(prev => ({ ...prev, startDate, endDate }));
    }
  }, [filter.timelineFilter]);

  useEffect(() => {
    if (entity && entityId && (timelineFilter || (filter?.startDate && filter?.endDate))) {
      dispatch({
        type: GET_DASHBOARD,
        payload: {
          urlParams: {
            entity: entity,
            id: entityId,
            timelineFilter: timelineFilter,
            startDate: filter?.startDate,
            endDate: filter?.endDate,
          },
        },
      });
    } else {
      cogoToast.error('You can not redirect to booking dashboard');
      history.push('/v2/dashboard');
    }
  }, [location?.search, filter?.startDate, isRefresh]);

  useEffect(() => {
    if (getDashboardLoading) {
      setLaoding(true);
    } else {
      setLaoding(false);
    }
  }, [getDashboardLoading]);

  const openCalendar = () => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.open();
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === 'Dashboard') {
      history.push('/dashboard');
    } else if (title === 'Template Management') {
      history.push('/surge/templates');
    }
  };

  const updateURLParams = (data?: { value: string; label: string }) => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      timelineFilter: data?.value ?? '',
    };

    const newUrl = queryString.stringify(updatedParams);
    if (data) setFilter({ startDate: '', endDate: '', timelineFilter: data });

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {laoding && <Loader />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-2">
            <BreadcrumbTrail title={getDashboard?.dashboardName} navigationby={breadcrumNavigationHandler} navigation={['Dashboard']} />
            <div style={{ borderLeft: '3px solid #405189', height: '28px' }}></div>
            <p className="mt-1 fw-semibold">
              {filter?.startDate && filter.endDate && (
                <>
                  {moment(filter.startDate).format('D MMM')} - {moment(filter.endDate).format('D MMM')}
                </>
              )}
            </p>
            <div className="mt-1">
              <i onClick={openCalendar} style={{ cursor: 'pointer' }} className="ri-pencil-fill"></i>
            </div>
            <input ref={inputRef} type="text" placeholder="Select date" style={{ display: 'none' }} />
          </div>
          <div className="d-flex gap-3">
            <div style={{ height: 40 }}>
              <Select
                options={bookingOptions}
                placeholder="Select Type"
                value={filter?.timelineFilter}
                styles={{
                  control: provided => ({
                    ...provided,
                    width: '200px', // Set custom width
                  }),
                }}
                onChange={data => updateURLParams(data)}
              />
            </div>

            <button style={{ height: 37 }} className="btn btn-success d-flex align-items-center gap-1" onClick={() => setIsRefresh(!isRefresh)}>
              <i className=" ri-refresh-line"></i>
              Refresh
            </button>
          </div>
        </div>

        {/* SLA Wise Bookings */}
        {data?.slaWiseBookings && (
          <Card style={{ borderRadius: '10px', marginBottom: '1rem' }}>
            <CardBody>
              <div className="d-flex gap-2" style={{ alignItems: 'baseline', marginBottom: '10px' }}>
                <p className="fs-20 fw-medium m-0">SLA Wise Bookings</p>
                <p style={{ color: '#999999' }} className="fs-12 m-0">
                  {moment().format('dddd D, MMM hh:mm:ss')}
                </p>
              </div>

              {/* Scrollable Container */}
              <div className="sla-container">
                <div className="sla-scroll">
                  {data?.slaWiseBookings?.cards.map((slaBooking, index) => (
                    <SLACard key={index} slaBooking={slaBooking} />
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        {/*  Status Wise Bookings */}
        {data?.statusWiseBookings && (
          <Card style={{ borderRadius: '10px' }}>
            <CardBody>
              <div className="d-flex gap-2" style={{ alignItems: 'baseline' }}>
                <p className="fs-20 fw-medium">Status Wise Bookings</p>
                <p style={{ color: '#999999' }} className="fs-12">
                  {moment().format('dddd D, MMM hh:mm:ss')}
                </p>
              </div>

              {/* Scrollable Container */}
              <div className="sla-container p-1">
                <div className="status-scroll">
                  {data?.statusWiseBookings.cards.map((statusBooking, index: number) => (
                    <StatusCard key={index} statusBooking={statusBooking} />
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        {/*  Action Items On Bookings */}
        {data?.actionTypeBookings && (
          <Card style={{ borderRadius: '10px' }}>
            <CardBody>
              <div className="d-flex gap-2" style={{ alignItems: 'baseline' }}>
                <p className="fs-20 fw-medium">Action Items on Bookings</p>
                <p style={{ color: '#999999' }} className="fs-12">
                  {moment().format('dddd D, MMM hh:mm:ss')}
                </p>
              </div>

              {/* Scrollable Container */}
              <div className="sla-container p-1">
                <div className="action-scroll">
                  {data?.actionTypeBookings?.cards.map((actionBooking, index: number) => (
                    <ActionBookingCard key={index} actionBooking={actionBooking} />
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        <Row>
          {data?.projectWiseBookings && (
            <DonutChart
              lastUpdatedAt={data?.projectWiseBookings?.lastUpdatedAt}
              data={data?.projectWiseBookings?.data}
              title={'Project Wise Bookings'}
            />
          )}

          {data?.pickAndDrops && (
            <DonutChart lastUpdatedAt={data?.projectWiseBookings?.lastUpdatedAt} data={data?.pickAndDrops?.data} title={'Pickups vs Drops'} />
          )}

          {data?.regionWiseChart && <GeoChart data={data?.regionWiseChart?.data} title={'Region Wise Bookings'} />}
        </Row>
      </Container>
    </div>
  );
};

export default BookingDashboard;
