import { useState, useEffect } from 'react';

import { Row, Col, TabPane, Label, Card, CardBody } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';

const Preview = props => {
  const [benefitGroup, setBenefitGroup] = useState(null);
  const [benefitGroupItems, setBenefitGroupItems] = useState(null);

  useEffect(() => {
    setBenefitGroup(props?.data?.benefitGroup);
    setBenefitGroupItems(props?.data?.benefitGroupItems);
  }, [props]);

  return (
    <>
      <TabPane tabId={3} id="pills-bill-info">
        {/* <button
          onClick={(e) => {
            e.preventDefault()
            console.log(benefitGroup, benefitGroupItems)
          }}
        >
          get state12
        </button> */}
        <Row>
          {/* ------------------------- General Properties ------------------------- */}
          <Col md={12}>
            <Card>
              <PreviewCardHeader title="General Properties" />
              <CardBody>
                <Row>
                  <Col md={4}>
                    <Label className="form-label text-muted">Benefit Group Name</Label>: <strong>{benefitGroup?.displayName}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Currency</Label>:<strong>{benefitGroup?.benefitWorthCurrency?.value}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Total Packs Released</Label>: <strong>{benefitGroup?.totalPacksReleased}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Total Packs Limit</Label>: <strong>{benefitGroup?.totalPacksLimit}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Pack Release Type</Label>: <strong>{benefitGroup?.packReleaseType?.value}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Membership Generation Restriction</Label>:
                    <strong>{benefitGroup?.membershipGenerationRestriction?.toString()}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Eligible Data Upload Restriction</Label>:
                    <strong>{benefitGroup?.dataUploadRestriction?.toString()}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Benefit Pack Validity Duration Unit</Label>:{' '}
                    <strong>{benefitGroup?.validityTat?.unit?.value}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Benefit Pack Validity Duration value</Label>:{' '}
                    <strong>{benefitGroup?.validityTat?.value?.value}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Expiry Logic</Label>: <strong>{benefitGroup?.membership?.expiry?.value}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Benefit Group Category</Label>: <strong>{benefitGroup?.groupCategory?.value}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Is Active</Label>:<strong>{benefitGroup?.isActive?.toString()}</strong>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* ------------------------- Membership Properties ------------------------- */}
          <Col md={12}>
            <Card>
              <PreviewCardHeader title="Membership Properties" />
              <CardBody>
                <Row>
                  <Col md={4}>
                    <Label className="form-label text-muted">Membership Pre-fix</Label>: <strong>{benefitGroup?.membership?.prefix}</strong>
                  </Col>

                  <Col md={4}>
                    <Label className="form-label text-muted">Dynamic Unique Code Length</Label>:
                    <strong>{benefitGroup?.membership?.length?.toString()}</strong>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* ------------------------- Benefit Group Items ------------------------- */}
          <Col md={12}>
            <Card>
              <PreviewCardHeader title="Benefit Group Item(s)" />
              <CardBody>
                <Row>
                  {benefitGroupItems?.map((groupItem, groupItemIndex) => {
                    if (groupItem?.complementaryBenefit) {
                      return (
                        <Col md={3} key={groupItemIndex}>
                          <Card>
                            <CardBody>
                              <div className="d-flex mb-2 preview-branding">
                                <div className="flex-shrink-0 avatar-sm">
                                  <div className="avatar-title bg-light rounded">
                                    <img
                                      // src="https://design.thriwe.com/thriwe-one-admin/assets/images/brands/unnamed.png"
                                      src={groupItem?.benefitGroupItem?.completeData?.images?.logo}
                                      alt=""
                                      className="avatar-xxs"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3 ">
                                  <h5 className="fs-15 mb-1">{/* {service?.partner?.name} */}</h5>
                                  <p className="text-muted mb-2">{groupItem?.benefitGroupItem?.completeData?.name}</p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="flex-fill">
                                  <strong>2</strong>&nbsp; per Year
                                </div>
                                <div className="flex-fill">
                                  <i className="bi bi-arrow-right "></i>
                                </div>
                                <div className="flex-fill">
                                  <strong>{groupItem?.benefitGroupItem?.completeData?.quota?.delayedMinTat}</strong>
                                  &nbsp; per
                                  <strong>{groupItem?.benefitGroupItem?.completeData?.quota?.delayedMinValue}</strong>{' '}
                                  {Constant.CALENDER_DATA[groupItem?.benefitGroupItem?.completeData?.quota?.delayedMinTatUnit - 1]}
                                </div>
                              </div>
                              <div className="corner-badge">
                                <div className="badge badge-soft-success">Complimentray</div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    } else if (groupItem?.discountedBenefit) {
                      return (
                        <>
                          <Col md={3}>
                            <Card>
                              <CardBody>
                                <div className="d-flex mb-2 preview-branding">
                                  <div className="flex-shrink-0 avatar-sm">
                                    <div className="avatar-title bg-light rounded">
                                      <img
                                        src="https://design.thriwe.com/thriwe-one-admin/assets/images/brands/unnamed.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                  </div>

                                  <div className="flex-grow-1 ms-3">
                                    <h5 className="fs-15 mb-1">{/* {service?.partner?.name} */}</h5>
                                    <p className="text-muted mb-2">{groupItem?.benefitGroupItem?.completeData?.name}</p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="flex-fill">
                                    <strong>2</strong>
                                    &nbsp; per Year
                                  </div>
                                  <div className="flex-fill">
                                    <i className="bi bi-arrow-right "></i>
                                  </div>
                                  <div className="flex-fill">
                                    <strong>{groupItem?.benefitGroupItem?.completeData?.quota?.delayedMinTat}</strong>
                                    &nbsp; per
                                    <strong>{groupItem?.benefitGroupItem?.completeData?.quota?.delayedMinValue}</strong>{' '}
                                    {Constant.CALENDER_DATA[groupItem?.benefitGroupItem?.completeData?.quota?.delayedMinTatUnit - 1]}
                                  </div>
                                </div>
                                <div className="corner-badge">
                                  <div className="badge badge-soft-primary">25% Off</div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </>
                      );
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* ------------------------- FOOTER NAVIGATION ------------------------- */}
        <div className="d-flex align-items-start gap-3 mt-4">
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              props.toggleTab(props.activeTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
            Back to Benefit Group Item(s)
          </button>
          <button
            type="button"
            className="btn btn-primary btn-label right ms-auto nexttab"
            onClick={() => {
              props.onSubmit(props?.data);
              // props.toggleTab(props.activeTab + 1)
            }}
          >
            <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
            Submit
          </button>
        </div>
      </TabPane>
    </>
  );
};

export default Preview;
