import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

import { Error } from '../../../../../../Common';
import { CANCEL_BOOKING_BY_VENDOR, CANCEL_BOOKING_BY_VENDOR_EMPTY } from '../../../../../../../store/actions';

const DeclineBooking = ({ onClose, remarksList, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [isShowError, setIsShowError] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { declineBooking, declineBookingLoading } = useSelector(state => ({
    declineBooking: state?.CancelBookingByVendor?.data,
    declineBookingLoading: state?.CancelBookingByVendor?.loading,
  }));

  useEffect(() => {
    if (declineBooking !== null) {
      dispatch({
        type: CANCEL_BOOKING_BY_VENDOR_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: CANCEL_BOOKING_BY_VENDOR_EMPTY,
      });
    };
  }, [declineBooking]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    if (label === `reason_id`) {
      setSelectedRemark(data);
    } else {
      setIsShowError(false);
    }
  };

  /* ---------------------------- DECLINE BOOKING HANDLER ---------------------------- */
  const declineBookingHandler = () => {
    if (selectedRemark === null) {
      setIsShowError(true);
    } else {
      dispatch({
        type: CANCEL_BOOKING_BY_VENDOR,
        payload: {
          booking_id: `${currentSelectedBooking?.bookingId}`,
          reason_id: `${selectedRemark?.value}`,
        },
      });
    }
  };

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose}>
        <h4>Reason for decline the booking</h4>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12} className="mb-3">
            <Select
              id="decline-remarks"
              name="decline-remarks"
              placeholder="Select reason for decline the booking"
              value={selectedRemark}
              options={remarksList?.remarks?.map((remark, index) => ({
                label: `${remark?.name}`,
                value: `${remark?.id}`,
              }))}
              onChange={data => inputSelectHandler(`reason_id`, data)}
            />
            {isShowError && selectedRemark === null && <Error text="Kindly select reason for declining" ref={null} />}
          </Col>

          <Col md={12} className="mt-3 mb-4 text-center">
            {!declineBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }} onClick={declineBookingHandler}>
                Cancel Booking
              </Button>
            )}

            {declineBookingLoading && (
              <Button color="primary" style={{ fontSize: '14px', width: '160px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DeclineBooking;
