import queryString from 'query-string';
import React from 'react';
import CountUp from 'react-countup';
import { FaFire } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const ActionBookingCard = ({ actionBooking }) => {
  const history = useHistory();

  const routeHandler = () => {
    const params = {
      cardId: actionBooking?.cardId,
      timelineFilter: actionBooking?.data?.timelineFilter,
    };
    if (actionBooking?.toggleAction) {
      history.push({
        pathname: '/v2/booking/airport-transfer',
        search: `?${queryString.stringify(params)}`,
      });
    }
  };
  return (
    <Card
      style={{
        border: '1px solid #FFFFFF',
        borderRadius: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Added boxShadow
        cursor: actionBooking?.toggleAction && 'pointer',
      }}
      onClick={routeHandler}
      className="sla-card"
    >
      <CardBody>
        <div className="d-flex justify-content-between">
          <div
            className="icon-container"
            style={{
              background: '#E62E3F1A',
              padding: '10px',
              borderRadius: '10px',
              height: '40px',
            }}
          >
            <FaFire className="icon-fire" size={20} color="#EA9C2B" />
          </div>
          <div>
            <p className="m-0" style={{ fontWeight: '500', fontSize: '16px' }}>
              {actionBooking?.displayName}
            </p>
            <div style={{ textAlign: 'end' }}>
              <CountUp
                separator=""
                className="fw-semibold"
                start={0}
                end={Number(actionBooking?.data?.totalCount)}
                duration={2}
                style={{ fontSize: '25px', fontWeight: '500' }}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActionBookingCard;
