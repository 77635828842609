import { Constant } from '../Helpers/constant';

/* ---------------------------- COMMON ---------------------------- */
export const customHeaders = {
  'Content-Type': 'application/json',
  'Project-Code': Constant?.PROJECT_CODE ?? `ADMIN_ACCESS`,
  'X-Requested-By': [1, 4].includes(Number(localStorage.getItem('userType'))) ? 'INTERNAL' : localStorage.getItem('userType') == 2 ? 'CLIENT' : 'VENDOR',
};

export const clientCustomHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'Project-Code': localStorage?.getItem('bankCode') ?? 'MASTERCARD_KSA',
    'X-Requested-By': localStorage.getItem('userType') == 1 ? 'INTERNAL' : localStorage.getItem('userType') == 2 ? 'CLIENT' : 'VENDOR',
  };
};

export const commsHeader = {
  'X-Thriwe-Comms-Key': process.env.REACT_APP_COMS_KEY ?? ``,
};

// API base URL
export const masterCardBaseURL = `https://stag-mastercard-api.thriwe.com`;

/* ---------------------------- API URL'S ---------------------------- */
export const indianProjectsBaseURL = [
  `SAB_LANDMARK`,
  `SAB`,
  `ALRAJHI_LANDMARK`,
  `ALRAJHI_GOLF`,
  `ALRAJHI_VALET`,
  `THRIWE_VALET_PARKING`,
  `ENBD_VALET`,
]?.includes(Constant?.PROJECT_CODE)
  ? process.env.REACT_APP_KSA_URL
  : process.env.REACT_APP_INDIA_PROJECTS_URL;

export const uaeProjectsBaseURL = [
  `SAB_LANDMARK`,
  `SAB`,
  `ALRAJHI_LANDMARK`,
  `ALRAJHI_GOLF`,
  `ALRAJHI_VALET`,
  `THRIWE_VALET_PARKING`,
  `ENBD_VALET`,
]?.includes(Constant?.PROJECT_CODE)
  ? process.env.REACT_APP_KSA_URL
  : process.env.REACT_APP_UAE_PROJECTS_URL;

export const ksaProjectsBaseURL = [
  `SAB_LANDMARK`,
  `SAB`,
  `ALRAJHI_LANDMARK`,
  `ALRAJHI_GOLF`,
  `ALRAJHI_VALET`,
  `THRIWE_VALET_PARKING`,
  `ENBD_VALET`,
]?.includes(Constant?.PROJECT_CODE)
  ? process.env.REACT_APP_KSA_URL
  : process.env.REACT_APP_KSA_PROJECTS_URL;

export const adibProjectsUrl = process.env.REACT_APP_ADIB_PROJECTS_URL;

export const realTimeBaseURL = process.env.REACT_APP_REAL_TIME_BASE_URL;

export const thriweMarketPlaceUrl = [`ALRAJHI_GOLF`]?.includes(Constant.PROJECT_CODE)
  ? process.env.REACT_APP_THRIWE_MARKETPLACE_KSA_PROJECTS_URL
  : process.env.REACT_APP_THRIWE_MARKETPLACE_PROJECTS_URL;
export const thriweMarketPlaceUaeUrl = process.env.REACT_APP_THRIWE_MARKETPLACE_UAE_PROJECTS_URL;
export const thriweDiscoveryUrl = [`ALRAJHI_GOLF`]?.includes(Constant.PROJECT_CODE)
  ? process.env.REACT_APP_THRIWE_MARKETPLACE_KSA_PROJECTS_URL
  : process.env.REACT_APP_THRIWE_DISCOVERY_PROJECTS_URL;

export const thriweDiscoveryUaeUrl = process.env.REACT_APP_THRIWE_DISCOVERY_UAE_URL;

export const umsUrl = ['HSBC_BEACH_CLUB', 'ENBD_NATIONAL_DAY', 'MASTERCARD_FAB'].includes(Constant.PROJECT_CODE)
  ? process.env.REACT_APP_UMS_UAE_URL
  : ['ALRAJHI_GOLF'].includes(Constant.PROJECT_CODE)
    ? process.env.REACT_APP_UMS_KSA_URL
    : process.env.REACT_APP_UMS_URL;

let projectBaseURL = ``;
const contractingCountry = JSON.parse(localStorage?.getItem('data'))?.program?.contractingCountry?.name.toLowerCase();
if (
  contractingCountry === `india` ||
  (Constant.PROJECT_CODE === 'GLOBE_TOYOTA' && Constant.userTypes.CLIENT === Number(localStorage.getItem('userType')))
) {
  projectBaseURL = indianProjectsBaseURL;
} else if (contractingCountry === `united arab emirates`) {
  projectBaseURL = uaeProjectsBaseURL;
} else {
  projectBaseURL = uaeProjectsBaseURL;
}

export const baseUrl = projectBaseURL;

export const adminAPIURL = process.env.REACT_APP_ADMIN_API_URL;
