import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

import { useEffect } from 'react';
import { CONFIRM_BOOKING_BY_VENDOR, CONFIRM_BOOKING_BY_VENDOR_EMPTY } from '../../../../../../../store/actions';
import RunningCar from './../../../../../../../assets/custom/running-car.gif';

const BookingConfirm = ({ onClose, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { confirmByVendor, confirmByVendorloading } = useSelector(state => ({
    confirmByVendor: state?.ConfirmBookingByVendor?.data,
    confirmByVendorloading: state?.ConfirmBookingByVendor?.loading,
  }));

  useEffect(() => {
    if (confirmByVendor !== null) {
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });
    };
  }, [confirmByVendor]);

  const confirmBookingHandler = () => {
    dispatch({
      type: CONFIRM_BOOKING_BY_VENDOR,
      payload: {
        body: {
          booking_id: currentSelectedBooking?.bookingId,
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose} style={{ borderBottom: '1px solid #E9EAEB', alignItems: 'flex-start' }}>
        <p className="confirm-heading">Confirm</p>
        <p className="confirm-sub-heading">Are you sure you want to confirm this booking</p>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12} className="mb-3">
            <div>
              <div className="confirm-label">Service date & time</div>
              <div className="confirm-service-date">
                {currentSelectedBooking?.serviceDateTime
                  ? moment(new Date(`${currentSelectedBooking?.serviceDateTime}`)).format('DD/MM/YYYY h:mm A')
                  : 'NA'}
              </div>
              <div className="running-car-container">
                <img src={RunningCar} className="running-car" />
              </div>
            </div>
          </Col>

          <Col md={12} className="mb-3">
            <Row>
              <Col md={6}>
                <div className="text-center">
                  <div className="confirm-label">Pick-up Location</div>
                  <div className="confirm-body">{currentSelectedBooking?.pickupLocation}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="text-center">
                  <div className="confirm-label">Drop-off Location</div>
                  <div className="confirm-body">{currentSelectedBooking?.dropLocation}</div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={12} className="pt-3" style={{ borderTop: '1px solid #E9EAEB' }}>
            <div className="text-center d-flex justify-content-between mb-2">
              <div className="confirm-label">Customer name</div>
              <div className="confirm-body">Adil Salman</div>
            </div>

            <div className="text-center d-flex justify-content-between mb-2">
              <div className="confirm-label">Region</div>
              <div className="confirm-body">{currentSelectedBooking?.region}</div>
            </div>

            <div className="text-center d-flex justify-content-between mb-2">
              <div className="confirm-label">Zone</div>
              <div className="confirm-body">{currentSelectedBooking?.zone}</div>
            </div>
          </Col>

          <Col md={12} className="mt-3 mb-3 text-center d-flex gap-2 pt-4" style={{ borderTop: '1px solid #E9EAEB' }}>
            {!confirmByVendorloading && (
              <Button className="flex-grow-1" color="primary" style={{ fontSize: '14px' }} onClick={confirmBookingHandler}>
                Yes
              </Button>
            )}

            {confirmByVendorloading && (
              <Button className="flex-grow-1" color="primary" style={{ fontSize: '14px' }}>
                <Spinner className="btn-replacer-spinner" />
              </Button>
            )}

            <button
              className={`btn btn-outline-primary flex-grow-1`}
              onClick={() => {
                onClose();
              }}
            >
              <span className="d-flex align-items-center">
                <span className="flex-grow-1">No</span>
              </span>
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default BookingConfirm;
