import React from 'react';
import { UserFrameIcon } from '../../../../../../Components/icons';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GET_DASHBOARD_DETAILS_EMPTY } from '../../../../../../store/actions';
import CountUp from 'react-countup';

const BookingTypeCard = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const routeHandler = () => {
    const params = {
      entity: item?.toggleAction?.entityType,
      entityId: item?.toggleAction?.entityId,
      timelineFilter: item?.data?.timelineFilter,
    };
    if (item?.toggleAction) {
      history.push({
        pathname: '/v2/booking-dashboard',
        search: `?${queryString.stringify(params)}`,
      });
    }
    dispatch({
      type: GET_DASHBOARD_DETAILS_EMPTY,
    });
  };

  return (
    <div className="d-flex justify-content-between sla-card" style={{ cursor: item?.toggleAction && 'pointer' }} onClick={routeHandler}>
      <div>
        <UserFrameIcon />
      </div>
      <div className="text-end">
        <p className="text-black text-format-7 m-0">{item?.displayName}</p>
        <CountUp separator="" start={0} end={Number(item?.data?.totalCount)} duration={2} className="fw-bold text-format-3 m-0" />

        {/* <p className="mb-0 text-color-light-grey justify-content-end gap-2 d-flex">
                <RateValue value={item?.data?.changePercentage} fill={item?.data?.changePercentageColor ?? ''} />
                <span>Since Yesterday</span>
              </p> */}
      </div>
    </div>
  );
};

export default BookingTypeCard;
