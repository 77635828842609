import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, Button, Col, Form, Label, Row, Input, CardFooter } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import ReactTooltip from 'react-tooltip';
import { CREATE_PARENT_PROJECT, GET_CLIENTS, GET_COUNTRY } from '../../../../../../../store/application/actionType';
import { doNotAllowSpecialsCharacters, isValidUrl, removeSpaceToUnderscoreAndCaps } from '../../../../../../Helpers/Helpers';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { baseUrl, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import Select from 'react-select';

const Platform = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    internalName: '',
    description: '',
    code: '',
    client: null,
    url: '',
    brandingType: null,
    contractingCountry: null,
    isActive: true,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [isSelectedPlatform, setIsSelectedPlatform] = useState(false);
  const [platform, setPlatform] = useState({});
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [client, setClient] = useState({});

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Country List
  const { countries } = useSelector(state => ({
    countries: state?.Countries?.data,
  }));

  // Get Client List
  const { clients } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    // Countries
    if (countries == null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    // Clients
    if (clients === null) {
      dispatch({
        type: GET_CLIENTS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => {
          return {
            value: `${country?.countryId}`,
            label: `${country?.name}`,
          };
        }),
      );
    }

    if (clients !== null) {
      setAllClients(
        clients?.results?.map(client => {
          return {
            value: `${client?.id}`,
            label: `${client?.name}`,
            data: client,
          };
        }),
      );
    }

    if (props?.selectedClient) {
      inputSelectHandler('client', props?.selectedClient);
    }
  }, [countries, clients]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: doNotAllowSpecialsCharacters(value) ? value : state?.name,
        };
        break;

      case 'internalName':
        updatedState = {
          ...state,
          internalName: doNotAllowSpecialsCharacters(value) ? value : state?.internalName,
          code: doNotAllowSpecialsCharacters(value) ? removeSpaceToUnderscoreAndCaps(value) : state?.internalName,
        };
        break;

      case 'url':
        updatedState = {
          ...state,
          url: value,
        };
        break;

      case 'code':
        updatedState = {
          ...state,
          code: value,
        };
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: e.target.checked,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
    } else if (label === `client`) {
      updatedState = {
        ...state,
        client: data,
        contractingCountry: {
          label: data?.data?.contractingCountry?.name ? data?.data?.contractingCountry?.name : data?.completeData?.contractingCountry?.name,
          value: data?.data?.contractingCountry?.id ? data?.data?.contractingCountry?.id : data?.completeData?.contractingCountry?.id,
        },
      };
    } else if (label === `brandingType`) {
      updatedState = {
        ...state,
        brandingType: data,
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (flag, data) => {
    if (
      data?.name?.trim() !== `` &&
      data?.internalName?.trim() !== `` &&
      data?.client !== null &&
      data?.contractingCountry !== null &&
      data?.url?.trim() !== `` &&
      isValidUrl(data?.url) &&
      data?.description?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- LOAD CLIENT ---------------------------- */
  async function loadClient(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/clients`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.id,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- SELECT CLIENT ---------------------------- */
  const onSelectClient = selectedOption => {
    setClient(selectedOption);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    props?.toggleTab(3);

    if (validateErrors(`submissionCheck`, state)) {
      const body = {
        ...state,
        client: {
          objectId: state?.client?.value,
        },
        contractingCountry: {
          id: `${state?.contractingCountry?.value}`,
          name: state?.contractingCountry?.label,
        },
      };

      dispatch({
        type: CREATE_PARENT_PROJECT,
        payload: {
          data: body,
        },
      });
      props?.toggleTab(3);
    }
  };

  async function loadPlatform(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/parent-projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
        clientName: client?.completeData?.name,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const onSelectPlatform = selectedOption => {
    setPlatform(selectedOption);
    setState({
      name: selectedOption?.completeData?.name,
      internalName: selectedOption?.completeData?.internalName,
      code: selectedOption?.completeData?.internalName ? removeSpaceToUnderscoreAndCaps(selectedOption?.completeData?.internalName) : '',
      url: selectedOption?.completeData?.url,
      description: selectedOption?.completeData?.description,
      isActive: selectedOption?.completeData?.isActive,
    });
  };

  const tog_varying1 = () => {
    props?.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="create-client"
        size="lg"
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Platform
          </h5>
          <Button type="button" onClick={tog_varying1} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
        </div>
        <ModalBody>
          <Row>
            <Col xxl={12}>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Label htmlFor="client" className="form-label">
                            Client
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                        </div>
                        <AsyncPaginate
                          debounceTimeout={500}
                          value={client}
                          loadOptions={loadClient}
                          onChange={selectedOption => onSelectClient(selectedOption)}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => 'No results found'}
                          cacheUniqs={[['code']]}
                          placeholder="Select..."
                        />
                        {isValidationShow && state?.client === null && <Error text="Kindly select client" />}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <Input
                            role="switch"
                            type="checkbox"
                            id="has-parent-category"
                            name="has-parent-category"
                            className="form-check-input"
                            checked={isSelectedPlatform}
                            onChange={() => {
                              setIsSelectedPlatform(!isSelectedPlatform);
                            }}
                          />
                          <Label className="form-check-label" htmlFor="has-parent-category">
                            Already Platform
                          </Label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} className="mb-4">
                      <div className="d-flex justify-content-between">
                        <Label htmlFor="choices-multiple-default" className="form-label">
                          Select Platform
                          <span className="lbl_mandatory"></span>
                        </Label>
                      </div>
                      <AsyncPaginate
                        debounceTimeout={500}
                        // value={platform}
                        loadOptions={loadPlatform}
                        onChange={selectedOption => onSelectPlatform(selectedOption)}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'No results found'}
                        cacheUniqs={[['code']]}
                        placeholder="Select..."
                        isDisabled={!isSelectedPlatform}
                      />
                    </Col>
                  </Row>
                  <hr style={{ backgroundColor: '#9ea0a1' }} />
                  <Row>
                    {/* -------------------- start -------------------- */}

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform Display Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="name" name="name" type="text" className="form-control" onChange={inputChangeHandler} value={state?.name} />
                        {isValidationShow && state?.name?.trim() === `` && <Error text="Kindly enter display name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform Internal Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="internalName"
                          name="internalName"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.internalName}
                        />

                        {isValidationShow && state?.internalName?.trim() === `` && <Error text="Kindly enter internal name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform Code
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="code"
                          name="code"
                          type="text"
                          disabled={true}
                          className="form-control text-uppercase"
                          // onChange={inputChangeHandler}
                          value={state?.code}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="" className="form-label">
                          Contracting Country
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="contractingCountry"
                          name="contractingCountry"
                          value={state?.contractingCountry}
                          options={allCountries}
                          onChange={data => inputSelectHandler(`contractingCountry`, data)}
                        />
                        {isValidationShow && state?.contractingCountry === null && <Error text="Kindly select country" />}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform URL
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="url" name="url" type="text" className="form-control" onChange={inputChangeHandler} value={state?.url} />
                        {isValidationShow && (state?.url?.trim() === `` || !isValidUrl(state?.url?.trim())) && (
                          <Error text="Kindly enter valid url" />
                        )}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Description
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <textarea
                          rows="3"
                          id="description"
                          name="description"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.description}
                        />
                        {isValidationShow && state?.description?.trim() === `` && <Error text="Kindly enter description" />}
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="mb-3">
                        <Label htmlFor="isActive" className="form-label">
                          Is Active?
                        </Label>
                        <div className="d-flex align-items-center">
                          <span className="text-muted">False &nbsp;</span>
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher form-switch-md cursor-pointer"
                              type="checkbox"
                              id="isActive"
                              name="isActive"
                              checked={state?.isActive}
                              onChange={inputChangeHandler}
                            />
                          </div>
                          <span> &nbsp;True</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <CardFooter>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                  {Object.keys(platform)?.length > 0 ? 'Update' : 'Create'}
                </button>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Modal>
    </>
  );
};

export default Platform;
