import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL, umsUrl } from '../../../../Containers/Config/index.js';
import { CLICK_DOWNLOAD_DUMP, CLICK_DOWNLOAD_DUMP_SUCCESS, CLICK_DOWNLOAD_DUMP_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* postDownloadDump({ payload }) {
  let url = payload?.code ? `${umsUrl}/admin/v1/download` : `${uaeProjectsBaseURL}/admin/downloads`;
  const shouldSendAdibProjectCode = Constant?.PROJECT_CODE === 'ADIB_V2' && Constant.userTypes.CLIENT === Number(localStorage.getItem('userType'));

  try {
    const response = yield call(apiCall, {
      url: url,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...(shouldSendAdibProjectCode && { 'Project-Code': 'ADIB' }), // Add project-code conditionally in headers
      },
      params: {
        ...payload?.urlParam,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: CLICK_DOWNLOAD_DUMP_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: CLICK_DOWNLOAD_DUMP_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CLICK_DOWNLOAD_DUMP_ERROR, payload: error });
  }
}

export function* watchDownloadDump() {
  yield takeEvery(CLICK_DOWNLOAD_DUMP, postDownloadDump);
}

function* downloadDumpSaga() {
  yield all([fork(watchDownloadDump)]);
}

export default downloadDumpSaga;
