import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { Input } from 'reactstrap';
import CogoToast from 'cogo-toast';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customHeaders, thriweDiscoveryUrl } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FILTER_VALUES, GET_FILTERS_LIST } from '../../../../../../../store/actions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Loader } from '../../../../../../Common';
import cogoToast from 'cogo-toast';

const initialState = {
  user_id: '',
  start_date: '',
  end_date: '',
  order_by: 'desc',
};

interface RootState {}

const Filter = props => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(initialState);
  const [filterType, setFilterType] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]); // Array to store applied filters
  const [filterOptions, setFilterOptions] = useState([]);
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const filterContainerRef = useRef(null);

  console.log('selectedOption', selectedOption);

  const { filterAPiOptions, filterValues } = useSelector((state: any) => ({
    filterAPiOptions: state.GetFiltersList.data,
    filterValues: state.GetFilterValues.data,
  }));

  useEffect(() => {
    if (filterContainerRef.current) {
      filterContainerRef.current.scrollLeft = filterContainerRef.current.scrollWidth;
    }
  }, [appliedFilters]);

  useEffect(() => {
    if (filterAPiOptions !== null) {
      setFilterOptions(
        filterAPiOptions?.results?.map(filter => {
          return {
            value: filter?.key,
            label: filter?.name,
            data: filter,
          };
        }),
      );
    }
  }, [filterAPiOptions]);

  useEffect(() => {
    if (filterValues !== null) {
      setDynamicOptions(
        filterValues?.results?.map(filter => {
          return {
            value: filter?.id,
            label: filter?.name,
          };
        }),
      );
    }
  }, [filterValues]);

  const inputChangeHandler = e => {
    const { value } = e.target;
    if (value?.trim()) {
      setFilter({
        ...filter,
        [filterType?.value]: value, // Apply trim on change
      });
    } else {
      setFilter({
        ...filter,
        [filterType?.value]: '',
      });
    }
  };

  const inputSelectHandler = data => {
    let existingValues = filter[filterType?.value] ? filter[filterType?.value].split(',') : [];
    console.log('existingValues', existingValues);
    if (!existingValues.includes(data?.value)) {
      existingValues.push(data?.value);
    } else {
      cogoToast.error('Filter Already Selected');
    }
    console.log('exitingValues 2', existingValues);
    const updatedValue = existingValues.join(',');

    setFilter({
      ...filter,
      [filterType?.value]: updatedValue,
    });

    submitHandler({
      ...filter,
      [filterType?.value]: updatedValue,
    });

    setSelectedOption([...selectedOption, data]);
  };

  const submitHandler = (filters = null) => {
    let trimmedValue = filter[filterType?.value]?.trim();

    if (filters) {
      trimmedValue = filters[filterType?.value]?.trim();
    }

    // General Empty Validation
    if (!trimmedValue) {
      CogoToast.error('Field cannot be empty'); // Show toast error
      return;
    }

    // Email Validation (Allow only @ and . as special characters)
    if (filterType?.value === 'customer_email_id') {
      const emailRegex = /^[a-zA-Z0-9]+([@.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(trimmedValue)) {
        CogoToast.error('Please enter a valid email address'); // Show toast error
        return;
      }
    }

    // Mobile Number Validation (Only digits allowed)
    if (filterType?.value === 'customer_mobile_number') {
      if (!/^\d+$/.test(trimmedValue)) {
        CogoToast.error('Mobile number should contain only digits'); // Show toast error
        return;
      }
    }

    // Check if filter type already exists
    const updatedFilters = appliedFilters.filter(f => f.key !== filterType?.value);
    updatedFilters.push({
      key: filterType?.value,
      label: filterType?.label,
      value: trimmedValue,
    });

    setAppliedFilters(updatedFilters);

    if (!filters) {
      // Reset filter field for the current type
      setFilter({ ...filter, [filterType?.value]: '' });
    }

    // Transform appliedFilters into the desired format
    const filtersObject = updatedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    props?.filterHandler({ ...filter, ...filtersObject });
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submitHandler();
    }
  };

  const removeFilter = (key, valueToRemove) => {
    const updatedFilters = appliedFilters
      .map(f => {
        if (f.key === key) {
          const newValues = f.value.split(',').filter(val => val !== valueToRemove);
          return { ...f, value: newValues.join(',') };
        }
        return f;
      })
      .filter(f => f.value !== ''); // Remove empty filters

    setAppliedFilters(updatedFilters);
    setFilter(prev => ({
      ...prev,
      [key]: updatedFilters.find(f => f.key === key)?.value || '',
    }));

    props?.filterHandler(
      updatedFilters.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {}),
    );
  };

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectFilterType = event => {
    setFilterType(event);
    setFilter({ ...filter, [event.value]: '' });
    if (event?.data?.componentType === 'DROPDOWN') {
      dispatch({
        type: GET_FILTER_VALUES,
        payload: {
          urlParams: {
            filter: event?.value,
          },
        },
      });
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Select
            options={filterOptions}
            placeholder="Select"
            value={filterType}
            onChange={e => onSelectFilterType(e)}
            styles={{
              control: provided => ({
                ...provided,
                width: '200px', // Set custom width
              }),
            }}
            isSearchable={false}
            className="filter-select"
          />
          {(filterType === null || filterType?.data?.componentType === 'INPUT') && (
            <Input
              id="name"
              name="name"
              type={'text'}
              className="form-control"
              placeholder="Search"
              value={filter?.[filterType?.value] || ''}
              onChange={inputChangeHandler}
              autoComplete="off"
              disabled={filterType === null}
              style={{ width: '200px' }}
              onKeyPress={handleKeyPress}
            />
          )}

          {filterType?.data?.componentType === 'DROPDOWN' && (
            <Select
              options={dynamicOptions}
              placeholder={`Select ${filterType?.label}`}
              value={null}
              // value={dynamicOptions?.find(option => option?.value === filter?.[filterType?.value])}
              onChange={e => inputSelectHandler(e)}
              styles={{
                control: provided => ({
                  ...provided,
                  width: '200px', // Set custom width
                }),
              }}
              isSearchable={false}
            />
          )}
        </div>

        <div
          className="filter-conrainer"
          ref={filterContainerRef}
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '10px',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            width: '412px',
            scrollBehavior: 'smooth',
          }}
        >
          {appliedFilters?.map(({ key, label, value }) =>
            value.split(',').map((v, index) => (
              <div
                key={`${key}-${index}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  background: '#abb9e8',
                  padding: '3px 0px 3px 4px',
                  borderRadius: '4px',
                  whiteSpace: 'nowrap', // Prevent wrapping
                }}
              >
                <span className="fs-10">{selectedOption?.find(option => v === option?.value)?.label || v}</span>
                <button style={{ border: 'none', background: 'transparent', cursor: 'pointer' }} onClick={() => removeFilter(key, v)}>
                  &times;
                </button>
              </div>
            )),
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
