import React from 'react';
import { Switch, Route } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';
import NormalLayout from '../Layouts/NormalLayout';

//routes
import { authProtectedRoutes, publicRoutes, authV2ProtectedRoutes } from './allRoutes';
import { AuthProtected, AccessRoute } from './AuthProtected';
import { Dashboard, Home } from '../Containers/Pages';
import { Constant } from '../Containers/Helpers/constant';
import { VendorLogin } from '../Containers/Pages/VendorPanel/Enbd';
import VendorPanelDashboard from '../Containers/Pages/VendorPanel/Comman/Dashboard';

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map(r => r.path);
  let availableAuthRoutesPath = authProtectedRoutes.map(r => r.path);
  let availableV2AuthRoutesPath = authV2ProtectedRoutes.map(r => r.path);

  let testAuthProtectedRoutes = [];
  let testV2AuthProtectedRoutes = [];
  const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));

  if (userType === Constant?.userTypes?.ADMIN_USER_V2 || userType === Constant?.userTypes?.VENDOR_USER_V2) {
    authV2ProtectedRoutes?.forEach(route => {
      testV2AuthProtectedRoutes.push(route);
    });
  } else if (!JSON.parse(localStorage?.getItem('data'))) {
    testAuthProtectedRoutes = [
      { path: '/dashboard', component: Dashboard },
      { path: '/', Component: Home },
    ];
  } else if (!JSON.parse(localStorage?.getItem('data'))?.program) {
    testAuthProtectedRoutes = [
      { path: '/dashboard', component: Dashboard },
      { path: '/', component: Home },
    ];
  } else {
    if (userType === Constant?.userTypes?.CLIENT) {
      if (JSON.parse(localStorage.getItem('data'))?.program?.code === `SAB_LANDMARK`) {
        authProtectedRoutes?.forEach(route => {
          if (['GENERATE_VOUCHER', 'VOUCHERS_REPORT', 'CLIENT_DOWNLOAD_DUMP']?.includes(route?.name)) {
            testAuthProtectedRoutes.push(route);
          }
        });
      } else if (JSON.parse(localStorage.getItem('data'))?.program?.code === `ADIB_V2`) {
        authProtectedRoutes?.forEach(route => {
          const permissions = JSON.parse(localStorage.getItem('authUser'))?.permissions ?? [];
          if (permissions?.includes(route?.name)) {
            testAuthProtectedRoutes.push(route);
          }
        });
        testAuthProtectedRoutes.push({ path: '/client/dashboard', component: Dashboard }, { path: '/client/login', component: Home });
      } else if (JSON.parse(localStorage.getItem('data'))?.program?.code === `GLOBE_TOYOTA`) {
        authProtectedRoutes?.forEach(route => {
          if (
            [
              'REFERRAL',
              'VALIDATE_REFERRAL',
              'LEDGER_LIST',
              'VOUCHER',
              'VALIDATE_VOUCHER',
              'FILE_UPLOAD_V1',
              'BOOKING_MANAGEMENT',
              'CUSTOMER_MANAGEMENT',
              'UNIQUE_BOOKING_V2_READ',
              'UNIQUE_BOOKING_V2_UPDATE',
              'DOWNLOAD_ACCESS',
              'DYNAMIC_COUPON_V2_READ',
              'ANNOUNCEMENT_READ',
            ]?.includes(route?.name)
          ) {
            testAuthProtectedRoutes.push(route);
          }
        });
        testAuthProtectedRoutes.push({ path: '/client/dashboard', component: Dashboard }, { path: '/client/login', component: Home });
      } else {
        authProtectedRoutes?.forEach(route => {
          if (
            [
              'CLIENT_AIRPORT_TRANSFER',
              'CLIENT_AIRPORT_MEET_AND_GREET',
              'CLIENT_HISTORY',
              'CLIENT_CONTACT',
              'CLIENT_DOWNLOAD_DUMP',
              'GENERATE_VOUCHER',
              'VOUCHERS_REPORT',
            ]?.includes(route?.name)
          ) {
            testAuthProtectedRoutes.push(route);
          }
        });
        testAuthProtectedRoutes.push({ path: '/client/dashboard', component: Dashboard }, { path: '/client/login', component: Home });
      }
    } else if (userType === Constant?.userTypes?.VENDOR) {
      if (JSON.parse(localStorage.getItem('data'))?.program?.code === `ENBD_V3`) {
        authProtectedRoutes?.forEach(route => {
          if (['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'VENDOR_BOOKING_MANAGEMENT', 'VENDOR_AIRPORT_TRANSFER']?.includes(route?.name)) {
            testAuthProtectedRoutes.push(route);
          }
        });
      } else if (JSON.parse(localStorage.getItem('data'))?.program?.code === `SAB`) {
        authProtectedRoutes?.forEach(route => {
          if (['VENDOR_QR', 'VENDOR_VALET_PARKING', 'VENDOR_REPORT', 'VENDOR_DOWNLOAD_DUMP']?.includes(route?.name)) {
            testAuthProtectedRoutes.push(route);
          }
        });
      } else if (JSON.parse(localStorage.getItem('data'))?.program?.code === `THRIWE_DINING_V1`) {
        authProtectedRoutes?.forEach(route => {
          if (['VENDOR_DOWNLOAD_DUMP', 'DINING_BOOKING_MANAGEMENT']?.includes(route?.name)) {
            testAuthProtectedRoutes.push(route);
          }
        });
      }

      testAuthProtectedRoutes.push({ path: '/vendor/dashboard', component: VendorPanelDashboard }, { path: '/vendor/login', component: VendorLogin });
    } else {
      authProtectedRoutes?.forEach(route => {
        if (JSON.parse(localStorage?.getItem('authUser'))?.permissions?.includes(route?.name)) {
          testAuthProtectedRoutes.push(route);
        }
      });
      testAuthProtectedRoutes.push({ path: '/dashboard', component: Dashboard }, { path: '/', component: Home });
    }
  }

  availableAuthRoutesPath = testAuthProtectedRoutes?.map(r => r.path);

  return (
    <React.Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Route exact path={route.path} component={route.component} key={idx} />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        <Route path={availableAuthRoutesPath}>
          <AuthProtected>
            <VerticalLayout>
              <Switch>
                {testAuthProtectedRoutes?.map((route, idx) => {
                  return <AccessRoute path={route.path} component={route.component} key={idx} exact={true} name={route?.name} />;
                })}
                {/* <Route path="*" component={NotFound} /> */}
              </Switch>
            </VerticalLayout>
          </AuthProtected>
        </Route>

        <Route path={availableV2AuthRoutesPath}>
          {/* <AuthProtected> */}
          <NormalLayout>
            <Switch>
              {testV2AuthProtectedRoutes?.map((route, idx) => {
                return <AccessRoute path={route.path} component={route.component} key={idx} exact={true} name={route?.name} />;
              })}
            </Switch>
          </NormalLayout>
          {/* </AuthProtected> */}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Index;
