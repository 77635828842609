import axios from 'axios';
import { removeEmptyAndNullValues } from '../../Containers/Helpers/Helpers';

export default async function apiCall(config) {
  const axiosConfig = {
    method: `${config.method}`,
    url: `${config.url}`,
    headers: {
      ...config.headers,
    },
    params: config?.params,
    data: config.isFormData ? config.data : JSON.stringify(config.data),
    paramsSerializer: customParamsSerializer,
    withCredentials: true,
  };

  const response = await axios(axiosConfig)
    .then(function (response) {
      return response;
    })
    .catch(err => {
      return err;
    });
  return response;
}

const customParamsSerializer = params => {
  return Object.entries(removeEmptyAndNullValues(params))
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
      }
      return `${key}=${encodeURIComponent(value?.toString() ?? '')}`;
    })
    .join('&');
};
