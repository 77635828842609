import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminAPIURL, customHeaders } from '../../../../../../Containers/Config/index.js';
import apiCall from '../../../../apiCall.js';
import { GET_FILTERS_LIST, GET_FILTERS_LIST_ERROR, GET_FILTERS_LIST_SUCCESS } from '../../../actions.js';

function* getFiltersList({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    console.log('payload', payload);

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v1/booking-filters`,
      method: 'GET',
      headers: {
        ...filteredHeaders,
      },
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: GET_FILTERS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_FILTERS_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_FILTERS_LIST_ERROR, payload: error });
  }
}

export function* watchGetFiltersList() {
  yield takeEvery(GET_FILTERS_LIST, getFiltersList);
}

function* getFiltersListSaga() {
  yield all([fork(watchGetFiltersList)]);
}

export default getFiltersListSaga;
