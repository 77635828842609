import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { LIST_BINS_EMPTY } from '../../../../../../../store/application/actionType';
import DataTable from 'react-data-table-component';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';

const BinsTable = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Benefit Id',
          selector: row => row?.benefitId,
          wrap: true,
          minWidth: '200px',
        },
        {
          name: 'Name',
          selector: row => row?.name,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Number',
          selector: row => row?.number,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Description',
          selector: row => row?.description,
          wrap: true,
          minWidth: '250px',
        },

        // {
        //   name: 'Actions',
        //   width: '150px',
        //   cell: row => (
        //     <div className="d-flex justify-content-center flex-column w-100">
        //       <span
        //         className="btn btn-sm btn-primary mb-1"
        //         onClick={() => {
        //           actionBtnHandler(`UPDATE_BIN`, row?.data);
        //         }}
        //       >
        //         Update
        //       </span>
        //     </div>
        //   ),
        // },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            name: data?.name || `NA`,
            number: data?.number || `NA`,
            description: data?.description || `NA`,
            benefitId: data?.binId,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  useEffect(() => {
    return () => {
      dispatch({
        type: LIST_BINS_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE_BIN`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h5 className="card-title mb-0 flex-grow-1">
                <span className="fw-light">Bins List - </span>
                {`${props?.selectedProject?.name}`}
              </h5>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BinsTable;
