import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, Input, Label, Col, Row, Spinner } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';

import { CHAUFFER_ASSIGN, CHAUFFER_ASSIGN_EMPTY, UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Error, Loader } from '../../../../../../../Common';
import {
  isANumber,
  isANumberWithDecimal,
  isValidAlphaNumberic,
  isValidAlphaNumbericWithoutSpace,
  isValidAplha,
  validateNumber,
} from '../../../../../../../Helpers/Helpers';

const ChaufferAssignModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    mobile: '',
    remarks: '',
  };

  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const { chaufferAssign, chauferAssignLoading } = useSelector(state => ({
    chaufferAssign: state?.ChaufferAssign?.data,
    chauferAssignLoading: state?.ChaufferAssign?.loading,
  }));
  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    let chaufferMobile = ``;
    let tempRemarks = ``;
    let chaufferName = ``;

    switch (name) {
      case 'chauffer-name':
        updatedState = {
          ...state,
          name: isValidAplha(value) ? value : state?.name,
          chaufferName: isValidAplha(value) ? value : state?.name,
        };
        break;

      case 'chauffer-mobile':
        if (value?.length === 0) {
          chaufferMobile = ``;
        } else {
          chaufferMobile = isANumber(value) ? value : state?.mobile;
        }
        updatedState = {
          ...state,
          mobile: chaufferMobile,
        };
        break;
      case 'vendor-remarks':
        if (value?.length === 0) {
          tempRemarks = ``;
        } else {
          tempRemarks = isValidAlphaNumberic(value) ? value : state?.remarks;
        }
        updatedState = {
          ...state,
          remarks: tempRemarks,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.name?.trim() !== `` && data?.mobile?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === 'submissionCheck' ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: CHAUFFER_ASSIGN,
        payload: {
          data: {
            chauffeur_name: state?.name,
            chauffeur_mobile: state?.mobile,
            vendor_remarks: state?.remarks,
          },
          urlParam: {
            bookingId: data?.booking_id,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (chaufferAssign !== null) {
      onCloseHandler(false);
      dispatch({
        type: CHAUFFER_ASSIGN_EMPTY,
      });
    }
  }, [chaufferAssign]);

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler();
      }}
      id="completed-bookings-modal"
      size="xl"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Chauffeur Assign
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          {/* {chauferAssignLoading && <Loader zIndex={true} />} */}
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="chauffer-name" className="form-label">
                    Chauffeur Name <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="chauffer-name"
                    name="chauffer-name"
                    type="text"
                    className="form-control"
                    value={state?.name}
                    onChange={inputChangeHandler}
                    placeholder="Chauffeur Name"
                  />
                </div>
                {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter chauffeur name" />}
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="chauffer-mobile" className="form-label">
                    Chauffeur Mobile No. <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="chauffer-mobile"
                    name="chauffer-mobile"
                    type="text"
                    className="form-control"
                    value={state?.mobile}
                    onChange={inputChangeHandler}
                    placeholder="Chauffeur Mobile"
                  />
                </div>
                {isValidationShow && state?.mobile?.trim() === `` && <Error text="Please enter chauffeur mobile" />}
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="vendor-remarks" className="form-label">
                    Vendor Remarks For Confirmed
                  </Label>
                  <Input
                    id="vendor-remarks"
                    name="vendor-remarks"
                    type="text"
                    className="form-control"
                    value={state?.remarks}
                    onChange={inputChangeHandler}
                    placeholder="Vendor Remarks For Confirmed"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="danger" onClick={() => onCloseHandler()}>
          Close
        </Button>
        {chauferAssignLoading ? (
          <Button color="primary" className="btn-load" outline>
            <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0">
                Loading...
              </Spinner>
              <span className="flex-grow-1 ms-2">Loading...</span>
            </span>
          </Button>
        ) : (
          <Button className="overlay-disable" color="primary" onClick={submitHandler}>
            Submit
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ChaufferAssignModal;
