import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import HTMLReactParser from 'html-react-parser';
import { RxCross1 } from 'react-icons/rx';
import { Button, Card, CardBody, CardHeader, Col, Label, Row, TabContent, TabPane } from 'reactstrap';
import { GET_BOOKING_DETAILS_EMPTY } from '../../../../../../../store/actions';

const Summary = ({ details, currentSelectedBooking, setCurrentSelectedBooking, setCurrentActiveBookingAction }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  useEffect(() => {
    scrollToSection('summary-section');
  }, [details]);

  const scrollToSection = id => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Row id="summary-section">
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1 fw-semibold fs-5">
                Booking ID - <span className="fw-normal text-decoration-underline cursor-pointer">{details?.results?.bookingId}</span>{' '}
              </h4>

              <div className="d-flex gap-3 align-items-center">
                {currentSelectedBooking?.actions?.includes(`CANCEL_BOOKING`) && (
                  <Button
                    className="customized-btn danger"
                    onClick={() => {
                      setCurrentActiveBookingAction(`CANCEL_BOOKING`);
                    }}
                  >
                    Cancel Booking
                  </Button>
                )}
                <span
                  className="cancel-details-icon"
                  onClick={() => {
                    dispatch({
                      type: GET_BOOKING_DETAILS_EMPTY,
                    });
                    setCurrentSelectedBooking(null);
                  }}
                >
                  <RxCross1 />
                </span>
              </div>
            </CardHeader>

            <CardHeader className="d-flex justify-content-end p-0">
              <div className="custom-tabs-container">
                {details?.results?.bookingDetail?.map((bookingDetail, index) => {
                  return (
                    <div
                      className={`custom-tab ${index === currentActiveTab ? 'active' : ''}`}
                      key={index}
                      onClick={() => setCurrentActiveTab(index)}
                    >
                      {bookingDetail?.tabDetails?.tabHeading}
                    </div>
                  );
                })}
              </div>
            </CardHeader>

            <CardBody>
              <TabContent activeTab={currentActiveTab}>
                {details?.results?.bookingDetail?.map((bookingDetail, index) => {
                  console.log('hheloo detail', bookingDetail?.tabDetails?.data);

                  if (bookingDetail?.tabDetails?.tabHeading === `Booking Logs`) {
                    return (
                      <TabPane tabId={index} key={index} className="mt-2">
                        <Row className="pe-4">
                          <Col md={5}>
                            <div className="modification-wrapper">
                              {bookingDetail?.tabDetails?.data?.map((logDetail, index) => (
                                <div className="modification-container" key={index}>
                                  <div className="d-flex gap-3 align-items-start">
                                    <div className="text-primary">
                                      <i className="mdi mdi-24px mdi-circle-slice-8" />
                                    </div>

                                    <div className="modification-details-section">
                                      <p className="modification-status-tag">{logDetail?.bookingStatus || ``}</p>
                                      <p className="modification-date">Date - {logDetail?.dateTime || ``}</p>
                                      <p className="modification-action-by">Action by - {logDetail?.actionBy || ``}</p>
                                      <p className="modification-remarks-section">
                                        Remarks - <span className="modification-remarks">Car assigned delayed chauffeur not available</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>

                          {/* {logDetail?.isActionAble?.status && (
                            <Col md={7}>
                              <div className="booking-truncate-details">
                                <div className="booking-prev-details-section">
                                  <p className="modification-section-heading">Booking Details</p>
                                  <DetailItem label="Booking Date" value="24/12/2024" />
                                  <DetailItem label="Booking Time" value="11:00 AM" />
                                  <DetailItem label="No. Of Passengers" value="3" />
                                  <DetailItem label="Pickup Location" value="Terminal 3, Dubai International Airport, United Ar....." />
                                  <DetailItem label="Drop Location" value="Terminal 3, Dubai International Airport, United Ar....." />
                                </div>
                                <div className="booking-prev-next-section">
                                  <p className="modification-section-heading">Modified Booking Details</p>
                                  <DetailItem label="Booking Date" value="24/12/2024" highlight />
                                  <DetailItem label="Booking Time" value="11:00 AM" highlight />
                                  <DetailItem label="No. Of Passengers" value="3" />
                                  <DetailItem label="Pickup Location" value="Terminal 3, Dubai International Airport, United Ar....." />
                                  <DetailItem label="Drop Location" value="Terminal 3, Dubai International Airport, United Ar....." />
                                </div>
                              </div>
                            </Col>
                          )} */}
                        </Row>
                      </TabPane>
                    );
                  } else {
                    return (
                      <TabPane tabId={index} key={index} className="mt-2">
                        <Row>
                          {bookingDetail?.tabDetails?.data?.map((tabDetail, index) => {
                            return (
                              <Row key={index}>
                                <Col md={12}>
                                  <p className="text-primary custom-section-heading">{tabDetail?.sectionHeading}</p>
                                </Col>

                                {tabDetail?.details?.map((details, index) => {
                                  return (
                                    <Col md={4} key={index}>
                                      <div className="mb-2 d-flex flex-column">
                                        <Label className="custom-section-label">
                                          {HTMLReactParser('')} {details?.heading}
                                        </Label>
                                        <Label className="custom-section-text">{details?.value}</Label>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            );
                          })}
                        </Row>
                      </TabPane>
                    );
                  }
                })}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Summary;

const events = ['Vendor Assigned', 'Chauffeur Reassigned', 'Booking Modified', 'Booking Completed'];

const DetailItem = ({ label, value, highlight }) => {
  return (
    <div className="modified-item">
      <span className="modified-label">{label}</span>
      <span className={`modified-value`}>{value}</span>
    </div>
  );
};
